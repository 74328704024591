import React from "react";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  input {
    width: 14px;
    height: 14px;
  }
  label {
    font-size: 14px;
    margin-left: 17px;
  }
`;

type CheckboxProps = {
  label: string;
  isChecked: boolean;
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

const Checkbox: React.FC<CheckboxProps> = (props) => {
  const { label, isChecked, handleChange } = props;
  return (
    <Container>
      <input type="checkbox" checked={isChecked} onChange={handleChange} />
      <label>{label}</label>
    </Container>
  );
};

export default Checkbox;
