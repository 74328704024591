import { MockApi } from "mocks/type";
import { USER_LOCAL_STORAGE_KEY } from "utils/config";
import store from "store";

const createSession: MockApi = {
  endpoint: "/v1/sessions",
  method: "post",
  behaviour: () => {
    store.set(USER_LOCAL_STORAGE_KEY, {
      name: "John Doe",
      email: "johndoe@gojek.com",
    });
  },
  response: {
    status: 201,
  },
};

const removeSession: MockApi = {
  endpoint: "/v1/sessions",
  method: "delete",
  behaviour: () => {
    store.remove(USER_LOCAL_STORAGE_KEY);
  },
  response: {
    status: 204,
  },
};

const userApis = [createSession, removeSession];

export default userApis;
