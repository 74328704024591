import React, { useMemo, useState } from "react";
import {
  convertFromRaw,
  convertToRaw,
  Editor,
  EditorState,
  RichUtils,
} from "draft-js";
import "draft-js/dist/Draft.css";
import styled from "styled-components";
import italicIcon from "./ic-italic.svg";
import underlineIcon from "./ic-underline.svg";
import orderedListIcon from "./ic-ordered-list.svg";
import unorderedListIcon from "./ic-unordered-list.svg";
import boldIcon from "./ic-bold.svg";
import separatorIcon from "./ic-separator.svg";
import { draftToMarkdown, markdownToDraft } from "markdown-draft-js";

const Container = styled.div<{ hasError?: boolean }>`
  border: 1px solid #dededf;
  border-radius: 4px;
  padding: 16px;
  border-color: ${(props) => props.hasError && "#ee2737"};

  .DraftEditor-root {
    height: 190px;
    width: 856px;
    overflow-y: auto;
    margin-top: 16px;
  }
  .DraftEditor-editorContainer,
  .public-DraftEditor-content {
    height: 100%;
    font-size: 14px;
    padding-right: 20px;
  }
`;

const Toolbar = styled.div`
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
  padding: 7px 9px;
  display: flex;
  align-items: center;
`;

const IconContainer = styled.div<{ active?: boolean }>`
  height: 21px;
  padding: 0px 8px;
  background: ${(props) => props.active && "#e4e4e4"};
  border-radius: 3px;
  margin-right: 4px;
`;

type Props = {
  placeholder?: string;
  hasError?: boolean;
  defaultValue?: string;
  onChange: (markdown: string) => void;
};

const RichTextEditor: React.FC<Props> = (props) => {
  const { placeholder, hasError, onChange, defaultValue = "" } = props;

  const initialEditorState = useMemo(() => {
    const rawData = markdownToDraft(defaultValue, {
      blockStyles: {
        ins_open: "UNDERLINE",
      },
      remarkableOptions: {
        enable: {
          inline: "ins",
        },
      },
    });
    const contentState = convertFromRaw(rawData);
    return EditorState.createWithContent(contentState);
  }, [defaultValue]);

  const [editorState, setEditorState] = useState(initialEditorState);
  const [currentValue, setCurrentValue] = useState(defaultValue);

  const handleKeyCommand = (command: string, editorState: EditorState) => {
    const newState = RichUtils.handleKeyCommand(editorState, command);

    if (!newState) {
      return "not-handled";
    }

    setEditorState(newState);
    return "handled";
  };

  const handleClickIconInline = (
    e: React.MouseEvent<HTMLImageElement, MouseEvent>,
    type: string
  ) => {
    e.preventDefault();
    handleChange(RichUtils.toggleInlineStyle(editorState, type));
  };

  const handleClickIconBlock = (type: string) => {
    handleChange(RichUtils.toggleBlockType(editorState, type));
  };

  const handleChange = (newEditorState: EditorState) => {
    setEditorState(newEditorState);

    const content = newEditorState.getCurrentContent();
    const rawObject = convertToRaw(content);
    const markdown = draftToMarkdown(rawObject, {
      styleItems: {
        UNDERLINE: {
          open: () => "++",
          close: () => "++",
        },
      },
    });

    if (markdown !== currentValue) {
      setCurrentValue(markdown);
      onChange(markdown);
    }
  };

  const inlineStyles = [
    { type: "BOLD", icon: boldIcon, alt: "Bold Icon" },
    { type: "ITALIC", icon: italicIcon, alt: "Italic Icon" },
    { type: "UNDERLINE", icon: underlineIcon, alt: "Underline Icon" },
  ];

  const blockStyles = [
    { type: "ordered-list-item", icon: orderedListIcon, alt: "Ordered Icon" },
    {
      type: "unordered-list-item",
      icon: unorderedListIcon,
      alt: "Unordered Icon",
    },
  ];

  const currentInlineStyle = editorState.getCurrentInlineStyle();
  const currentBlockType = RichUtils.getCurrentBlockType(editorState);

  return (
    <Container hasError={hasError}>
      <Toolbar>
        {inlineStyles.map(({ type, icon, alt }) => {
          return (
            <IconContainer active={currentInlineStyle.has(type)} key={type}>
              <img
                src={icon}
                alt={alt}
                onMouseDown={(e) => handleClickIconInline(e, type)}
              />
            </IconContainer>
          );
        })}

        <IconContainer>
          <img src={separatorIcon} alt="Separator" />
        </IconContainer>

        {blockStyles.map(({ type, icon, alt }) => {
          return (
            <IconContainer active={type === currentBlockType} key={type}>
              <img
                src={icon}
                alt={alt}
                onClick={() => handleClickIconBlock(type)}
              />
            </IconContainer>
          );
        })}
      </Toolbar>

      <Editor
        editorState={editorState}
        onChange={(newEditorState) => handleChange(newEditorState)}
        handleKeyCommand={handleKeyCommand}
        placeholder={placeholder}
      />
    </Container>
  );
};

export default RichTextEditor;
