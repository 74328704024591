import Button from "components/Button";
import styled from "styled-components";

export const ModalContent = styled.div`
  border: 1px solid #dededf;
  border-radius: 4px;
  margin: 14px 0 30px 0;
`;

export const SelectedPromoContainer = styled.div`
  padding: 24px 32px;
  border-bottom: 1px solid #dededf;
  font-size: 16px;
  background: #f9fafb;
`;

export const SelectMIDContainer = styled.div`
  padding: 24px 32px;
`;

export const LabelSelectMID = styled.div`
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 18px;
`;

export const OptionsContainer = styled.div`
  margin-left: -5px;
`;

export const ActionButtons = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const BackButton = styled(Button)`
  padding: 10px 40px;
  margin-right: 16px;
`;

export const SaveButton = styled(Button)`
  padding: 10px 40px;
`;
