import { MockApi } from "mocks/type";

const getOrderPageInfo: MockApi = {
  endpoint: "/feature-flag/v1/features/OrderPage",
  method: "get",
  behaviour: () => {},
  response: {
    data: {
      featureName: "OrderPage",
      isActive: true,
    },
    status: 200,
  },
};

const featureFlagApis = [getOrderPageInfo];

export default featureFlagApis;
