import { useEffect } from "react";
import { Column, usePagination, useTable } from "react-table";
import Pagination from "components/common/Table/Pagination";
import { TableContainer, Tbody, Thead } from "./style";

export interface TableProps {
  columns: Column[];
  data: Array<any>;
  onPaginationChange: (page: number, size: number) => void;
  variant?: "border";
  pagination?: boolean;
  onRowClick?: (id: string) => void;
  totalPage?: number;
}

export default function TableChangeLog(props: TableProps) {
  const {
    columns,
    data,
    onPaginationChange,
    variant,
    pagination = true,
    onRowClick = () => {},
    totalPage = 0,
  } = props;
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    gotoPage,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 },
      manualPagination: true,
      pageCount: totalPage,
    },
    usePagination
  );

  useEffect(() => {
    onPaginationChange(pageIndex, pageSize);
  }, [onPaginationChange, pageIndex, pageSize]);

  return (
    <>
      <TableContainer {...getTableProps()}>
        <Thead variant={variant}>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()} data-testid="head-columns">
                  {column.render("Header")}
                </th>
              ))}
            </tr>
          ))}
        </Thead>
        <Tbody {...getTableBodyProps()} variant={variant}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <tr
                {...row.getRowProps()}
                data-testid="table-rows"
                onClick={() => onRowClick(row.values.id)}
              >
                {row.cells.map((cell) => {
                  let value = cell.render("Cell");
                  return <td {...cell.getCellProps()}>{value}</td>;
                })}
              </tr>
            );
          })}
        </Tbody>
      </TableContainer>
      {pagination && (
        <Pagination
          gotoPage={gotoPage}
          totalPage={totalPage}
          pageIndex={pageIndex}
        />
      )}
    </>
  );
}
