import { MockApi } from "mocks/type";

const getPromoAuditLog: MockApi = {
  endpoint: "/v1/audit-logs",
  method: "get",
  behaviour: () => {},
  response: {
    data: {
      data: [
        {
          id: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
          actorEmail: "mock@mail.id",
          action: "updatePaymentMID",
          preActionDataState: " JSON STRING ",
          postActionDataState: "JSON STRING ",
          executedAt: "2021-09-08T09:56:50.239Z",
          message: "",
        },
        {
          id: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
          actorEmail: "mock@mail.id",
          action: "updatePaymentMID",
          preActionDataState: " JSON STRING ",
          postActionDataState: "JSON STRING ",
          executedAt: "2021-09-08T09:56:50.239Z",
          message: "",
        },
        {
          id: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
          actorEmail: "mock@mail.id",
          action: "updatePaymentMID",
          preActionDataState: " JSON STRING ",
          postActionDataState: "JSON STRING ",
          executedAt: "2021-09-08T09:56:50.239Z",
          message: "",
        },
        {
          id: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
          actorEmail: "mock@mail.id",
          action: "updatePaymentMID",
          preActionDataState: " JSON STRING ",
          postActionDataState: "JSON STRING ",
          executedAt: "2021-09-08T09:56:50.239Z",
          message: "",
        },
        {
          id: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
          actorEmail: "mock@mail.id",
          action: "updatePaymentMID",
          preActionDataState: " JSON STRING ",
          postActionDataState: "JSON STRING ",
          executedAt: "2021-09-08T09:56:50.239Z",
          message: "",
        },
        {
          id: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
          actorEmail: "mock@mail.id",
          action: "updatePaymentMID",
          preActionDataState: " JSON STRING ",
          postActionDataState: "JSON STRING ",
          executedAt: "2021-09-08T09:56:50.239Z",
          message: "",
        },
        {
          id: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
          actorEmail: "mock@mail.id",
          action: "updatePaymentMID",
          preActionDataState: " JSON STRING ",
          postActionDataState: "JSON STRING ",
          executedAt: "2021-09-08T09:56:50.239Z",
          message: "",
        },
        {
          id: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
          actorEmail: "mock@mail.id",
          action: "updatePaymentMID",
          preActionDataState: " JSON STRING ",
          postActionDataState: "JSON STRING ",
          executedAt: "2021-09-08T09:56:50.239Z",
          message: "",
        },
        {
          id: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
          actorEmail: "mock@mail.id",
          action: "updatePaymentMID",
          preActionDataState: " JSON STRING ",
          postActionDataState: "JSON STRING ",
          executedAt: "2021-09-08T09:56:50.239Z",
          message: "",
        },
        {
          id: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
          actorEmail: "mock@mail.id",
          action: "updatePaymentMID",
          preActionDataState: " JSON STRING ",
          postActionDataState: "JSON STRING ",
          executedAt: "2021-09-08T09:56:50.239Z",
          message: "",
        },
      ],
      totalPages: 5,
      currentPage: 1,
      perPage: 1,
    },
    status: 200,
  },
};

const auditLogApis = [getPromoAuditLog];

export default auditLogApis;
