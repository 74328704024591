import React from "react";
import promoAuditLogReducer from "./reducer";
import { PromoAuditLogDispatch, PromoAuditLogState } from "./type";

const defaultPromoAuditLogValue = {
  data: [],
  totalPages: 0,
  perPage: 10,
  currentPage: 1,
} as PromoAuditLogState;

export const PromoAuditLogContext = React.createContext<{
  state: PromoAuditLogState;
  dispatch: PromoAuditLogDispatch;
}>({
  state: defaultPromoAuditLogValue,
  dispatch: () => {},
});

const PromoAuditLogProvider: React.FC = ({ children }) => {
  const [state, dispatch] = React.useReducer(
    promoAuditLogReducer,
    defaultPromoAuditLogValue
  );

  const value = { state, dispatch };

  return (
    <PromoAuditLogContext.Provider value={value}>
      {children}
    </PromoAuditLogContext.Provider>
  );
};

export default PromoAuditLogProvider;
