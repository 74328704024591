import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import styled from "styled-components";
import colors from "utils/colors";
import IcCheckGreen from "./ic-check-green.svg";
import IcRemoveRed from "./ic-remove-red.svg";

const Toast = styled(ToastContainer).attrs({
  position: "top-center",
  autoClose: 3000,
  hideProgressBar: true,
  className: "toast-container",
})`
  .Toastify__toast--error {
    background-color: ${colors.error};
    .Toastify__toast-body {
      display: flex;
      align-items: center;
      &:before {
        content: " ";
        background-image: url(${IcRemoveRed});
        background-repeat: no-repeat;
        width: 20px;
        height: 20px;
        border-radius: 20px;
        background-color: white;
        display: inline-block;
        margin-right: 24px;
      }
    }
  }
  .Toastify__toast--success {
    background-color: ${colors.success};
    .Toastify__toast-body {
      display: flex;
      align-items: center;
      &:before {
        content: " ";
        background-image: url(${IcCheckGreen});
        background-repeat: no-repeat;
        background-position: 5px 6px;
        width: 20px;
        height: 20px;
        border-radius: 20px;
        background-color: white;
        display: inline-block;
        margin-right: 24px;
      }
    }
  }
  min-width: 500px;
`;

export default Toast;
