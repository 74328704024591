import styled from "styled-components";

export const ShippingPageTitle = styled.p`
  grid-column: 1/-1;
  font-size: 32px;
  line-height: 38px;
  color: #0a0000;
`;

export const ContentContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 10px;
  padding-bottom: 10px;
`;

export const DatepickerContainer = styled.div`
  grid-column: 1/5;
  float: left;
  input {
    float: left;
    width: 50%;
  }
`;

export const DropDownContainer = styled.div`
  grid-column: 1/1;
`;

export const InputFormContainer = styled.div`
  grid-column: 2/4;
`;

export const ButtonContainer = styled.div`
  display: flex;
  button {
    flex-grow: 1;
  }
`;
