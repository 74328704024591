import { getPromo } from "apis/promo";
import {
  assignMerchantIdsToPromoAPI,
  createPromoAPI,
  getAppliedMerchantIdsAPI,
  getPromoDetail,
  updatePromoAPI,
  updatePromoStatus,
} from "apis/promo/promo";
import AssignPromoModal from "components/AssignPromoModal";
import Button from "components/Button";
import ChangeLogModal from "components/ChangeLogModal";
import MapBusinessesModal from "components/MapBusinessesModal";
import PromoDetailModal from "components/PromoDetailModal";
import PromoFormModal from "components/PromoFormModal";
import TablePromo from "components/Table/TablePromo";
import React, { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
import {
  MerchantIdType,
  PromoDetailResponse,
  PromoRequest,
  SortType,
} from "utils/data/promo";
import { columns, promoDetailDefaultValue } from "./data";
import { ActionBar, PageTitle } from "./style";
import UpdateStatusPromoModal from "components/UpdateStatusPromoModal";
import PromoAuditLogProvider, {
  PromoAuditLogContext,
} from "stores/promo-audit-logs";

const PROMO_AUDIT_LOG_PARAM = {
  size: 10,
  actions:
    "patchPromoById,createPromoV1,processMIDMapping,updatePromoMids,updateTaskReadiness",
};

const PromoPageContent: React.FC = () => {
  const [showMapBusinessModal, setShowMapBusinessModal] = useState(false);
  const [showPromoDetailModal, setShowPromoDetailModal] = useState(false);
  const [showChangeLogModal, setShowChangeLogModal] = useState(false);
  const [data, setData] = useState<PromoDetailResponse[]>([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [currentSize, setCurrentSize] = useState(10);
  const [totalPage, setTotalPage] = useState(0);
  const [selectedPromoDetail, setSelectedPromoDetail] =
    useState<PromoDetailResponse>(promoDetailDefaultValue);
  const [showPromoForm, setShowPromoForm] = useState(false);
  const [showAssignPromoModal, setShowAssignPromoModal] = useState(false);
  const [appliedMerchantIds, setAppliedMerchantIds] = useState([]);
  const [showUpdateStatusPromoModal, setShowUpdateStatusPromoModal] =
    useState(false);
  const [currentSortType, setCurrentSortType] = useState("createdAt,desc");

  const callPromos = useCallback(async (page, size, sort) => {
    try {
      const response = await getPromo({
        page,
        size,
        sort,
      });
      setData(response.data.data);
      setTotalPage(response.data.totalPages);
    } catch (error) {
      toast.error("System failure, please try again.");
    }
  }, []);

  useEffect(() => {
    callPromos(currentPage, currentSize, currentSortType);
  }, [callPromos, currentPage, currentSize, currentSortType]);

  const onPaginationChange = (newPage: number, newSize: number) => {
    if (currentPage !== newPage || currentSize !== newSize) {
      setCurrentPage(newPage);
      setCurrentSize(newSize);
    }
  };

  const handleSubmitForm = async (values: PromoRequest, promoId: string) => {
    if (promoId) {
      try {
        await updatePromoAPI(values, promoId);
        toast.success("Changes successfully saved!");
        setShowPromoForm(false);
        const {
          data: { data: promoList },
        } = await getPromo({
          page: currentPage,
          size: currentSize,
          sort: currentSortType,
        });
        setData(promoList);
      } catch (error) {
        toast.error("System failure, please try again.");
      }
    } else {
      try {
        await createPromoAPI(values);
        toast.success("Promo successfully created!");
        setShowPromoForm(false);
        const {
          data: { data: promoList },
        } = await getPromo({
          page: currentPage,
          size: currentSize,
          sort: currentSortType,
        });
        setData(promoList);
      } catch (error) {
        toast.error("System failure, please try again.");
      }
    }
  };

  const callPromoDetail = useCallback(async (id: string) => {
    try {
      const response = await getPromoDetail({
        id,
      });
      setSelectedPromoDetail(response.data);
      setShowPromoDetailModal(true);
    } catch {
      toast.error("System failure, please try agaIn.");
    }
  }, []);

  const onRowClick = useCallback(
    (id: string) => {
      callPromoDetail(id);
    },
    [callPromoDetail]
  );

  const onPromoDetailClose = useCallback(
    async (isUpdateData: boolean) => {
      setShowPromoDetailModal(false);
      if (isUpdateData) {
        try {
          await callPromos(currentPage, currentSize, currentSortType);
        } catch (error) {
          toast.error("System failure, please try again.");
        }
      }
    },
    [callPromos, currentPage, currentSize, currentSortType]
  );

  const handleClickCreatePromo = () => {
    setSelectedPromoDetail(promoDetailDefaultValue);
    setShowPromoForm(true);
  };

  const handleClickEdit = () => {
    setShowPromoDetailModal(false);
    setShowPromoForm(true);
  };

  const handleClickAssignMID = async () => {
    try {
      const { data } = await getAppliedMerchantIdsAPI(selectedPromoDetail.id);
      setAppliedMerchantIds(data);
      setShowAssignPromoModal(true);
    } catch (e) {
      toast.error("System failure, please try again.");
    }
    setShowPromoDetailModal(false);
  };

  const handleSaveAssignPromo = async (
    currentMerchantIds: MerchantIdType[]
  ) => {
    try {
      const selectedMerchantIds = currentMerchantIds.filter(
        (mid) => mid.enabled
      );
      await assignMerchantIdsToPromoAPI(
        selectedMerchantIds,
        selectedPromoDetail.id
      );
      toast.success("Changes successfully saved!");
    } catch (e) {
      toast.error("System failure, please try again.");
    }
    setShowAssignPromoModal(false);
  };

  const handleUpdateStatusPromo = useCallback(() => {
    setShowPromoDetailModal(false);
    setShowUpdateStatusPromoModal(true);
  }, []);

  const closeUpdateStatusPromoModal = useCallback(() => {
    setShowUpdateStatusPromoModal(false);
  }, []);

  const onApproveUpdateStatue = useCallback(async () => {
    try {
      await updatePromoStatus(
        {
          active: !selectedPromoDetail.active,
        },
        selectedPromoDetail.id
      );
      onPromoDetailClose(true);
      setShowUpdateStatusPromoModal(false);
      toast.success(
        `Promo successfully ${
          selectedPromoDetail.active ? "deactivated" : "reactivated"
        }!`
      );
    } catch (error) {
      toast.error("System failure, please try again.");
    }
  }, [onPromoDetailClose, selectedPromoDetail.active, selectedPromoDetail.id]);

  const handleSortingChange = (id: string, sortType: SortType) => {
    setCurrentSortType(`${id},${sortType}`);
  };

  return (
    <div>
      <PageTitle>GoStore Promos</PageTitle>
      <ActionBar>
        <Button onClick={handleClickCreatePromo}>+ Create Promo</Button>
        <Button color="default" onClick={() => setShowMapBusinessModal(true)}>
          Map Businesses
        </Button>
        <Button color="default" onClick={() => setShowChangeLogModal(true)}>
          Change Logs
        </Button>
      </ActionBar>
      <TablePromo
        data={data}
        columns={columns}
        onPaginationChange={onPaginationChange}
        onRowClick={onRowClick}
        onSortingChange={handleSortingChange}
        totalPage={totalPage}
      />

      {showPromoForm && (
        <PromoFormModal
          onCancel={() => setShowPromoForm(false)}
          onSubmit={(values, promoId) => handleSubmitForm(values, promoId)}
          promoDetail={selectedPromoDetail}
        />
      )}

      {showMapBusinessModal && (
        <MapBusinessesModal onCancel={() => setShowMapBusinessModal(false)} />
      )}

      {showPromoDetailModal && (
        <PromoDetailModal
          onClose={onPromoDetailClose}
          promoData={selectedPromoDetail}
          onClickEdit={handleClickEdit}
          onClickAssignMID={handleClickAssignMID}
          onUpdateStatus={handleUpdateStatusPromo}
        />
      )}

      {showUpdateStatusPromoModal && (
        <UpdateStatusPromoModal
          isActivate={!selectedPromoDetail.active}
          promoId={selectedPromoDetail.id}
          onClickCancel={closeUpdateStatusPromoModal}
          onApprove={onApproveUpdateStatue}
        />
      )}

      {showChangeLogModal && (
        <PromoAuditLogProvider>
          <ChangeLogModal
            auditLogParam={PROMO_AUDIT_LOG_PARAM}
            auditLogContext={PromoAuditLogContext}
            dispatchType="UPDATE_PROMO_AUDIT_LOG"
            onClose={() => setShowChangeLogModal(false)}
          />
        </PromoAuditLogProvider>
      )}

      {showAssignPromoModal && (
        <AssignPromoModal
          onClickBack={() => setShowAssignPromoModal(false)}
          onClickSave={handleSaveAssignPromo}
          promoName={selectedPromoDetail.name}
          merchantIds={appliedMerchantIds}
        />
      )}
    </div>
  );
};

export default PromoPageContent;
