import styled from "styled-components";
import Button from "components/Button";

export const ModalContent = styled.div`
  border: 1px solid #dededf;
  border-radius: 4px;
  padding: 24px 32px;
  margin: 14px 0 230px 0;
`;

export const UploadInfo = styled.div`
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  color: rgba(51, 51, 51, 0.87);
  margin-bottom: 16px;
`;

export const UploadIcon = styled.img`
  margin: 0px 10px -3px 0px;
`;

export const ActionButtons = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const CancelButton = styled(Button)`
  padding: 10px 40px;
  margin-right: 16px;
`;

export const SubmitButton = styled(Button)`
  padding: 10px 40px;
`;

export const InputFile = styled.input`
  height: 35px;
  width: 106px;
  position: absolute;
  opacity: 0;
  margin-top: -34px;
  &:hover {
    cursor: pointer;
  }
`;

export const UploadButtonContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const UploadButton = styled.div`
  border: 1px solid #dededf;
  border-radius: 4px;
  padding: 8px 16px;
  min-width: 106px;
  font-size: 14px;
`;

export const StateIcon = styled.img`
  margin-left: 5px;
`;

export const ErrorMessage = styled.div`
  font-size: 12px;
  line-height: 14px;
  color: #ee2737;
  margin-top: 12px;
`;
