import { MockApi } from "mocks/type";

const getOrders: MockApi = {
  endpoint: "/internal-tool/v1/order-payment/list",
  method: "get",
  behaviour: () => {},
  response: {
    data: {
      data: [
        {
          outletId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
          customer: {
            name: "John",
            phoneNumber: "081234567890",
          },
          gostoreOrderId: "3fa85f64-5717-4562-b3fc-2c963f66afa7",
          shipping: {
            id: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
            trackingId: "3VTRE1",
          },
          gostoreStatus: "COMPLETE",
          payment: {
            id: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
            status: "SETTLED",
            type: "gopay",
            midtransStatus: "SETTLED",
          },
          createdAt: "2021-09-08T09:56:50.239Z",
          totalOrderPrice: 20000,
        },
      ],
      totalPages: 1,
      currentPage: 1,
      perPage: 1,
    },
    status: 200,
  },
};

const orderApis = [getOrders];

export default orderApis;
