import React from "react";

import ShopExtPageContent from "components/ShopExtPageContent";
import { ShopExtPageContainer } from "./style";

const ShopExtPage = () => (
  <ShopExtPageContainer>
    <ShopExtPageContent />
  </ShopExtPageContainer>
);

export default ShopExtPage;
