import { createGlobalStyle } from "styled-components";
import "@fontsource/lato";

export default createGlobalStyle`
    body {    
        font-family: 'Lato';
        margin: 0px;
        box-sizing: border-box;
        font-size: 18px;
    }

    *,
    *:before,
    *:after {
        box-sizing: inherit;
    }
`;
