import React from "react";
import shippingAuditLogReducer from "./reducer";
import { ShippingAuditLogDispatch, ShippingAuditLogState } from "./type";

const defaultShippingAuditLogValue = {
  data: [],
  totalPages: 0,
  perPage: 10,
  currentPage: 1,
} as ShippingAuditLogState;

export const ShippingAuditLogContext = React.createContext<{
  state: ShippingAuditLogState;
  dispatch: ShippingAuditLogDispatch;
}>({
  state: defaultShippingAuditLogValue,
  dispatch: () => {},
});

const ShippingAuditLogProvider: React.FC = ({ children }) => {
  const [state, dispatch] = React.useReducer(
    shippingAuditLogReducer,
    defaultShippingAuditLogValue
  );

  const value = { state, dispatch };

  return (
    <ShippingAuditLogContext.Provider value={value}>
      {children}
    </ShippingAuditLogContext.Provider>
  );
};

export default ShippingAuditLogProvider;
