import Modal from "components/common/Modal";
import React from "react";
import GoogleLogin, {
  GoogleLoginResponse,
  GoogleLoginResponseOffline,
} from "react-google-login";
import { useHistory } from "react-router-dom";
import { LoginModalContext } from "stores/login-modal";

import { login } from "apis/user";

import { UserContext } from "stores/user";
import { GOOGLE_LOGIN_CLIENT_ID } from "utils/config";
import { LoginBodyModal } from "./style";

const LoginPageModal = () => {
  const history = useHistory();

  const { dispatch } = React.useContext(UserContext);
  const { isShownModal, setIsShownModal } = React.useContext(LoginModalContext);

  const handleSuccess = (
    response: GoogleLoginResponse | GoogleLoginResponseOffline
  ) => {
    const googleLoginResponse = response as GoogleLoginResponse;
    const loginRequest = {
      googleTokenId: googleLoginResponse.getAuthResponse().id_token,
    };
    login(loginRequest).then(() => {
      const loginUser = {
        email: googleLoginResponse.getBasicProfile().getEmail(),
        name: googleLoginResponse.getBasicProfile().getName(),
      };
      dispatch({ type: "UPDATE_USER", payload: loginUser });
      setIsShownModal(false);
      history.push("/");
    });
  };

  return (
    <>
      {isShownModal && (
        <Modal
          title="Please sign-in"
          body={
            <LoginBodyModal>
              <GoogleLogin
                clientId={GOOGLE_LOGIN_CLIENT_ID}
                buttonText="Sign in with Google"
                onSuccess={handleSuccess}
                cookiePolicy={"single_host_origin"}
              />
            </LoginBodyModal>
          }
        />
      )}
    </>
  );
};
export default LoginPageModal;
