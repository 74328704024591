import React from "react";
import { InputForm } from "./style";

type Props = {
  placeholder: string;
  value: string;
  onInputChange: (payload: string) => void;
};

const ShippingForm: React.FC<Props> = (props) => {
  const { placeholder, value, onInputChange } = props;

  return (
    <InputForm data-testid="input-shipping-type">
      <input
        type="text"
        className="form-control"
        placeholder={placeholder}
        value={value}
        onChange={(e) => {
          const input = e.target.value;
          onInputChange(input);
        }}
      />
    </InputForm>
  );
};

export default ShippingForm;
