import React from "react";
import PromoPageContent from "components/PromoPageContent";
import { PromoPageContainer } from "./style";

const PromoPage = () => (
  <PromoPageContainer>
    <PromoPageContent />
  </PromoPageContainer>
);

export default PromoPage;
