import React, { useState } from "react";
import { ShippingOrder, TrackingOrder } from "utils/data/shipping";
import { DateTime } from "luxon";

import {
  FormContainer,
  LabelValueContainer,
  Label,
  Value,
  TrackingHeaderContainer,
  TrackingFormContainer,
  TrackingStatusItemContainer,
  TrackingStatusIcon,
  TrackingStatusDetail,
  ValueTrackingItem,
  LabelTrackingItem,
  TrackingStatusContent,
  TrackingDetailTitle,
  ShippingLogo,
} from "./style";
import ShippingFormModal from "components/ShippingFormModal";

type ShippingBodyProps = {
  shippingData: ShippingOrder;
};

const ShippingBody: React.FC<ShippingBodyProps> = (props) => {
  const { shippingData: values } = props;
  const [trackingList, setTrackingList] = useState<TrackingOrder[]>(
    values.tracking
  );
  const onFormSubmit = (order: ShippingOrder) => {
    setTrackingList(order.tracking);
  };

  return (
    <>
      <FormContainer>
        <LabelValueContainer>
          <Label>Gostore ID</Label>
          <Value data-testid="label-gostore-id">{values?.gostoreOrderId}</Value>
        </LabelValueContainer>
        <LabelValueContainer>
          <Label>Shipper Tracking ID</Label>
          <Value data-testid="label-shipper-tracking-id">
            {values?.shipperTrackingId}
          </Value>
        </LabelValueContainer>
        <LabelValueContainer>
          <Label>Airway Bill</Label>
          <Value data-testid="label-awb">{values?.awbNumber || `-`}</Value>
        </LabelValueContainer>
        <LabelValueContainer>
          <Label>Status</Label>
          <Value
            data-testid="label-status"
            className={values?.status.toLowerCase()}
          >
            {values?.status}
          </Value>
        </LabelValueContainer>
        <LabelValueContainer>
          <Label>Outlet Info</Label>
          <Value data-testid="label-outlet-name">{values?.merchant.name}</Value>
          <Value data-testid="label-outlet-phone">
            {values?.merchant.phoneNumber}
          </Value>
          <Value data-testid="label-outlet-address">
            {values?.merchantLocation.address}
          </Value>
        </LabelValueContainer>
        <LabelValueContainer>
          <Label>Customer Info</Label>
          <Value data-testid="label-customer-name">
            {values?.customer.name}
          </Value>
          <Value data-testid="label-customer-phone">
            {values?.customer.phoneNumber}
          </Value>
          <Value data-testid="label-customer-address">
            {values?.customerLocation.address}
          </Value>
        </LabelValueContainer>
        <LabelValueContainer>
          <Label>Courier Info</Label>
          <Value>
            <ShippingLogo
              src={values?.courier?.logo}
              alt=""
              data-testid="label-shipping-logo"
            />
          </Value>
          <Value data-testid="label-shipping-fee">{`Fee Rp. ${values?.courier?.rate.value}`}</Value>
          <Value data-testid="label-shipping-insurance">{`Insurance Rp. ${values?.courier.shippingInsuranceRate.value}`}</Value>
        </LabelValueContainer>
        <LabelValueContainer>
          <Label>Creation Date</Label>
          <Value data-testid="label-creation-date">{values.creationDate}</Value>
        </LabelValueContainer>
      </FormContainer>
      <TrackingFormContainer>
        <TrackingDetailTitle>Tracking Detail</TrackingDetailTitle>
        {values.active && (
          <ShippingFormModal values={values} onSubmit={onFormSubmit} />
        )}
        <TrackingHeaderContainer>
          <Label />
          <Label>Shipper Status</Label>
          <Label>Logistic Status</Label>
          <Label>Gostore Status</Label>
        </TrackingHeaderContainer>
        {[...trackingList].reverse().map((tracking, idx) => {
          return (
            <TrackingStatusItemContainer
              key={tracking.createdDate}
              status={tracking.trackingStatus}
              lastIndex={idx === trackingList.length - 1}
            >
              <TrackingStatusIcon status={tracking.trackingStatus} />
              <TrackingStatusDetail>
                <TrackingStatusContent>
                  <ValueTrackingItem data-testid="tracking-date">
                    {DateTime.fromISO(tracking.createdDate).toFormat(
                      "yyyy-MM-dd, hh:mm"
                    )}
                  </ValueTrackingItem>
                  <LabelTrackingItem data-testid="shipper-status-name">
                    {tracking.shipperStatus.name}
                  </LabelTrackingItem>
                  <ValueTrackingItem data-testid="shipper-status-desc">
                    {tracking.shipperStatus.description}
                  </ValueTrackingItem>
                </TrackingStatusContent>
                <TrackingStatusContent>
                  <LabelTrackingItem data-testid="logistic-status-name">
                    {tracking.logisticStatus.name}
                  </LabelTrackingItem>
                  <ValueTrackingItem data-testid="logistic-status-desc">
                    {tracking.logisticStatus.description}
                  </ValueTrackingItem>
                </TrackingStatusContent>
                <TrackingStatusContent>
                  <LabelTrackingItem data-testid="gostore-status-name">
                    {tracking.trackingStatus}
                  </LabelTrackingItem>
                  <ValueTrackingItem data-testid="gostore-status-desc">
                    {`Updated by ${tracking.createdBy}`}
                  </ValueTrackingItem>
                </TrackingStatusContent>
              </TrackingStatusDetail>
            </TrackingStatusItemContainer>
          );
        })}
      </TrackingFormContainer>
    </>
  );
};

export default ShippingBody;
