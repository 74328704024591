import { UserAction, UserState } from "./type";
import store from "store";
import { USER_LOCAL_STORAGE_KEY } from "utils/config";

const userReducer = (user: UserState, action: UserAction) => {
  switch (action.type) {
    case "UPDATE_USER": {
      store.set(USER_LOCAL_STORAGE_KEY, action.payload);
      return {
        ...action.payload,
      };
    }
    case "LOGOUT": {
      store.remove(USER_LOCAL_STORAGE_KEY);
      return {
        email: "",
        name: "",
      };
    }
    default: {
      return user;
    }
  }
};

export default userReducer;
