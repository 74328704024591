import HomePage from "./home/HomePage";
import OrderPage from "./order";
import PromoPage from "./promo";
import ShippingPage from "./shipping";
import ShopExtPage from "./shopext";

const RouterList = [
  {
    name: "GoStore Promos",
    path: "/promo",
    component: PromoPage,
    isSidebarItem: true,
    protected: true,
    isActive: () => true,
  },
  {
    name: "Shipping",
    path: "/shipping",
    component: ShippingPage,
    isSidebarItem: true,
    protected: true,
    isActive: () => true,
  },
  {
    name: "Order",
    path: "/order",
    component: OrderPage,
    isSidebarItem: true,
    protected: true,
    isActive: (feature) => true,
  },
  {
    name: "Shop-Ext",
    path: "/shop-ext",
    component: ShopExtPage,
    isSidebarItem: true,
    protected: true,
    isActive: (feature) => true,
  },
  {
    name: "Home",
    path: "/",
    component: HomePage,
    isSidebarItem: false,
    protected: false,
    isActive: () => true,
  },
];

export default RouterList;
