import React from "react";

import ShippingPageContent from "components/ShippingPageContent";
import { ShippingPageContainer } from "./style";

const ShippingPage = () => (
  <ShippingPageContainer>
    <ShippingPageContent />
  </ShippingPageContainer>
);

export default ShippingPage;
