export const data = [
  {
    time: "2019-11-18 16:38:12",
    log: "Theodore CREATED 000004 30% GoPay Cashback",
  },
  {
    time: "2019-11-18 16:38:12",
    log: "Theodore CREATED 000004 30% GoPay Cashback",
  },
  {
    time: "2019-11-18 16:38:12",
    log: "Theodore CREATED 000004 30% GoPay Cashback",
  },
  {
    time: "2019-11-18 16:38:12",
    log: "Theodore CREATED 000004 30% GoPay Cashback",
  },
  {
    time: "2019-11-18 16:38:12",
    log: "Theodore CREATED 000004 30% GoPay Cashback",
  },
];

export const columns = [
  {
    Header: "Time",
    accessor: "time",
  },
  {
    Header: "Logs",
    accessor: "log",
  },
];
