import React, { useState, useCallback } from "react";
import { toast } from "react-toastify";

import Button from "components/Button";
import ShopExtForm from "../ShopExtForm/ShopExtForm";

import {
  ShopExtPageTitle,
  ContentContainer,
  InputFormContainer,
  ButtonContainer,
} from "./style";
import { ReverifyDomainRequest } from "utils/data/shopext";
import { reverifyDomainAPI } from "apis/shopext";

const ShopExtPageContent: React.FC = () => {
  const defaultBusinessId = "";

  const [businessIdInput, setInput] = useState(defaultBusinessId);

  const callShopExtReverifyDomain = useCallback((mokaBusinessId: string) => {
    let reverifyDomain = {
      moka_business_id: mokaBusinessId,
    } as ReverifyDomainRequest;
    reverifyDomainAPI(reverifyDomain)
      .then(() => toast.info("Success"))
      .catch(() => toast.error("System failure, please try again"));
  }, []);

  const reverifyDomain = () => {
    callShopExtReverifyDomain(businessIdInput);
  };

  return (
    <>
      <ContentContainer>
        <ShopExtPageTitle data-testid="shop-ext-menu-title">
          GoStore Shop Ext
        </ShopExtPageTitle>
        <InputFormContainer>
          <ShopExtForm
            data-testid="input-business-id"
            placeholder="Input Business ID"
            value={businessIdInput}
            onInputChange={setInput}
          />
        </InputFormContainer>

        <ButtonContainer>
          <Button data-testid="button-submit" onClick={reverifyDomain}>
            Verify Domain
          </Button>
        </ButtonContainer>
      </ContentContainer>
    </>
  );
};

export default ShopExtPageContent;
