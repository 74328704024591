import { rest } from "msw";

import user from "./apis/user";
import auditLog from "./apis/audit-log";
import order from "./apis/order";
import featureFlag from "./apis/feature-flag";
import payment from "./apis/payment";

const apis = [...user, ...auditLog, ...order, ...featureFlag, ...payment];

export default apis.map((api) =>
  rest[api.method](api.endpoint, (req, res, ctx) => {
    api.behaviour(ctx);
    return res(
      ctx.status(api.response.status),
      api.response.data && ctx.json(api.response.data)
    );
  })
);
