import Button from "components/Button";
import OrderRefundModal from "components/OrderRefundModal";
import React, { useState } from "react";
import { OrderDetail } from "utils/data/order";
import { ActionContainer, RefundButton } from "./style";

type OrderDetailFooterProps = {
  onClose: () => void;
  orderData: OrderDetail;
};

const ELIGIBLE_REFUND_PAYMENT_METHOD = ["gopay", "qris", "credit_card"];
const ELIGIBLE_REFUND_PAYMENT_STATUS = ["CAPTURED", "SETTLED"];

const isEligibleForRefund = (orderData: OrderDetail): boolean => {
  return (
    ELIGIBLE_REFUND_PAYMENT_STATUS.includes(orderData?.payment?.status) &&
    ELIGIBLE_REFUND_PAYMENT_METHOD.includes(orderData?.payment?.type)
  );
};

const OrderDetailFooter: React.FC<OrderDetailFooterProps> = (props) => {
  const { onClose, orderData } = props;
  const [showOrderRefundModal, setShowOrderDetailModal] = useState(false);
  return (
    <ActionContainer>
      <RefundButton
        data-testid="btn-refund"
        color="danger"
        variant="outlined"
        onClick={() => setShowOrderDetailModal(true)}
        disabled={!isEligibleForRefund(orderData)}
      >
        Refund Order
      </RefundButton>
      <Button data-testid="btn-cancel" color="default" onClick={onClose}>
        Close
      </Button>
      {showOrderRefundModal && (
        <OrderRefundModal
          onClose={() => setShowOrderDetailModal(false)}
          orderData={orderData}
          onOrderDetailClose={onClose}
        />
      )}
    </ActionContainer>
  );
};

export default OrderDetailFooter;
