import { ShippingAuditLogAction, ShippingAuditLogState } from "./type";

const shippingAuditLogReducer = (
  shippingAuditLog: ShippingAuditLogState,
  action: ShippingAuditLogAction
) => {
  switch (action.type) {
    case "UPDATE_SHIPPING_AUDIT_LOG": {
      return {
        ...action.payload,
      };
    }
    default: {
      return shippingAuditLog;
    }
  }
};

export default shippingAuditLogReducer;
