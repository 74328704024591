import React from "react";
import Select, { StylesConfig, components } from "react-select";
import CaretDownIconUrl from "./caret-down.svg";

type Option = {
  value: string;
  label: string;
};

type Props = {
  options: Option[];
  placeholder: string;
  inputId?: string | "";
  selectedOption: Option | null; // Allow null to make placeholder visible
  handleChange: (selectedOption: Option | null) => void;
  hasError?: boolean;
};

type IsMulti = false;

const selectStyles: StylesConfig<Option, IsMulti> = {
  control: (provided: any, { selectProps: { hasError } }) => ({
    ...provided,
    borderWidth: "1px",
    borderStyle: "solid",
    borderColor: hasError ? "#ee2737;" : "#dededf",
    borderRadius: "3px",
    outline: "none",
    boxShadow: null,
    fontSize: "14px",
    minWidth: "260px",
    "&:hover": {
      borderColor: hasError ? "#ee2737;" : "#dededf",
    },
  }),
  placeholder: (provided: any) => ({
    ...provided,
    color: "#9b9b9b",
  }),
  option: (provided: any, state: { isSelected: boolean }) => {
    const { isSelected } = state;
    return {
      ...provided,
      "&:hover, &:focus, &:active, &:visited": {
        background: "#f8f8f8",
        outline: "none",
      },
      background: isSelected ? "#f8f8f8" : "none",
      color: "#131313",
      fontSize: "14px",
    };
  },
};

const CaretDownIcon = () => {
  return <img src={CaretDownIconUrl} alt="Caret Down" />;
};

const DropdownIndicator = (props: any) => {
  return (
    <components.DropdownIndicator {...props}>
      <CaretDownIcon />
    </components.DropdownIndicator>
  );
};

const Dropdown: React.FC<Props> = (props) => {
  const {
    options,
    placeholder,
    handleChange,
    selectedOption,
    hasError,
    inputId,
  } = props;

  return (
    <Select
      value={selectedOption}
      inputId={inputId}
      onChange={(option: Option | null) => handleChange(option)}
      options={options}
      placeholder={placeholder}
      components={{
        IndicatorSeparator: () => null,
        DropdownIndicator,
      }}
      styles={selectStyles}
      hasError={hasError}
    />
  );
};

export default Dropdown;
