import axios from "utils/axiosInstance";

export const uploadCSV = (request: FormData) => {
  return axios.post("/internal-tool/v1/mid-mappings", request, {
    headers: {
      "content-type": "multipart/form-data",
    },
  });
};

export const submitCSV = (taskId: string) => {
  return axios.patch(`/internal-tool/v1/tasks/${taskId}/readiness`, {
    isReadyToProcess: true,
  });
};
