import styled from "styled-components";

export const UnauthorizedPageContainer = styled.div`
  background-color: rgba(238, 39, 55, 0.06);
  padding: 40px 36px;
  color: #ee2737;
  border: 1px solid #ee2737;
`;

export const UnauthorizedPageContact = styled.a`
  text-decoration: none;
`;
