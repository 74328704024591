import React, { ReactNode } from "react";
import { Route } from "react-router-dom";
import { UserContext } from "stores/user";
import UnauthorizedPage from "components/UnauthorizedPage";

type RouteWithSubRoutesProps = {
  path?: string;
  protected?: boolean;
  routes?: ReactNode[];
  [x: string]: any;
};

const RouteWithSubRoutes: React.FC<RouteWithSubRoutesProps> = (props) => {
  const { state } = React.useContext(UserContext);

  if (props.protected && !state.name) {
    return <UnauthorizedPage />;
  }

  return (
    <Route
      path={props.path}
      render={(anotherProps) => <props.component {...anotherProps} />}
    />
  );
};

export default RouteWithSubRoutes;
