import axios from "utils/axiosInstance";
import {
  PromoParams,
  PromoRequest,
  PromoDetailRequest,
  UpdateStatusRequest,
  MerchantIdType,
} from "utils/data/promo";

export const getPromo = (params: PromoParams) => {
  return axios.get(`/internal-tool/v1/promos`, { params });
};

export const createPromoAPI = (request: PromoRequest) => {
  return axios.post("/internal-tool/v1/promos", request, {
    withCredentials: true,
  });
};

export const getPromoDetail = (request: PromoDetailRequest) => {
  const { id } = request;

  return axios.get(`/internal-tool/v1/promos/${id}`);
};

export const updatePromoStatus = (request: UpdateStatusRequest, id: string) => {
  return axios.patch(`/internal-tool/v1/promos/${id}`, request);
};

export const updatePromoAPI = (request: PromoRequest, promoId: string) => {
  return axios.patch(`/internal-tool/v1/promos/${promoId}`, request, {
    withCredentials: true,
  });
};

type MerchantIdsDataType = {
  MIDs: {
    MID: string;
    enabled: boolean;
  }[];
};

export const getAppliedMerchantIdsAPI = (promoId: string) => {
  return axios.get(`/internal-tool/v1/promos/${promoId}/mids`, {
    withCredentials: true,
    transformResponse: [
      (data) => {
        let appliedMerchantIds: MerchantIdType[] = [];

        if (typeof data === "string") {
          try {
            const merchantIdsData: MerchantIdsDataType = JSON.parse(data);

            appliedMerchantIds = merchantIdsData.MIDs.map(
              ({ MID, enabled }) => {
                return { merchantId: MID, enabled };
              }
            );
          } catch (e) {}
        }
        return appliedMerchantIds;
      },
    ],
  });
};

export const assignMerchantIdsToPromoAPI = (
  request: MerchantIdType[],
  promoId: string
) => {
  return axios.put(`/internal-tool/v1/promos/${promoId}/mids`, request, {
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
    transformRequest: [
      (request: MerchantIdType[]) => {
        const data = {
          MIDs: request.map(({ merchantId, enabled }) => ({
            MID: merchantId,
            enabled,
          })),
        };
        return JSON.stringify(data);
      },
    ],
  });
};
