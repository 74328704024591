import React from "react";

import OrderPageContent from "components/OrderPageContent";
import { OrderPageContainer } from "./style";

const OrderPage = () => {
  return (
    <OrderPageContainer>
      <OrderPageContent />
    </OrderPageContainer>
  );
};

export default OrderPage;
