import React from "react";
import { ActionContainer, Button } from "./style";
type PromoFooterProps = {
  closeModal: () => void;
};
const PromoFooter: React.FC<PromoFooterProps> = (props) => {
  const { closeModal } = props;
  return (
    <ActionContainer>
      <Button color="default" onClick={closeModal}>
        Close
      </Button>
    </ActionContainer>
  );
};

export default PromoFooter;
