import React from "react";
import { ActionContainer, Button } from "./style";

type FooterProps = {
  onClose: () => void;
};

const Footer: React.FC<FooterProps> = (props) => {
  const { onClose } = props;
  return (
    <ActionContainer>
      <Button color="default" onClick={onClose}>
        Close
      </Button>
    </ActionContainer>
  );
};

export default Footer;
