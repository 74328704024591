import React from "react";
import {
  BoldValue,
  OrderRefundBodyContainer,
  OrderRefundBodyReminder,
  OrderRefundBodyTotalRefund,
} from "./style";

type OrderBodyProps = {
  totalRefund: string;
};

const OrderBody: React.FC<OrderBodyProps> = (props) => {
  const { totalRefund } = props;
  return (
    <OrderRefundBodyContainer>
      <OrderRefundBodyReminder>
        Refunds cannot be undone and the refunder’s information will be
        recorded.
      </OrderRefundBodyReminder>
      <OrderRefundBodyTotalRefund>
        Total to be refunded: <BoldValue>Rp {totalRefund}</BoldValue>
      </OrderRefundBodyTotalRefund>
    </OrderRefundBodyContainer>
  );
};

export default OrderBody;
