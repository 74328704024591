import { PromoAuditLogAction, PromoAuditLogState } from "./type";

const promoAuditLogReducer = (
  promoAuditLog: PromoAuditLogState,
  action: PromoAuditLogAction
) => {
  switch (action.type) {
    case "UPDATE_PROMO_AUDIT_LOG": {
      return {
        ...action.payload,
      };
    }
    default: {
      return promoAuditLog;
    }
  }
};

export default promoAuditLogReducer;
