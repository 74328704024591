import styled from "styled-components";
import CaretDownIconUrl from "./caret-down.svg";

export const PaginationContainer = styled.div`
  margin-top: 40px;
  display: flex;
  button {
    border: 1px solid #e2e2e3;
    width: 34px;
    height: 34px;
    font-size: 12px;
    font-weight: 300;
    padding: 8px;
    cursor: pointer;
    background: white;
    color: rgba(0, 0, 0, 0.8);
    &.prev {
      border-radius: 4px 0px 0px 4px;
    }
    &.next {
      border-left: none;
      border-radius: 0px 4px 4px 0px;
    }
  }
  button[disabled] {
    cursor: not-allowed;
    color: rgba(16, 16, 16, 0.3);
  }
  ul.pagination {
    list-style: none;
    display: flex;
    margin: 0px;
    padding: 0px;

    li {
      background: white;
      border: 1px solid #e2e2e3;
      border-left: none;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 12px;
      color: rgba(0, 0, 0, 0.8);
      a {
        padding: 8px;
        min-width: 32px;
        height: 32px;
        text-align: center;
      }
      .active {
        background: #f4f4f4;
      }
      &:hover {
        cursor: pointer;
        background: #f4f4f4;
      }
      &.active {
        background: #f4f4f4;
      }
      &.disabled {
        cursor: not-allowed;
        color: rgba(16, 16, 16, 0.3);
        &:hover {
          background: white;
        }
      }
    }
  }
`;

export const TableContainer = styled.table`
  width: 100%;
  border-spacing: 0;
  border-collapse: collapse;
`;

interface TableProps {
  variant?: "border";
}

export const Thead = styled.thead<TableProps>`
  tr {
    background-color: #f9fafb;
    th {
      border: ${({ variant }) =>
        variant === "border" ? "1px solid #e2e2e3" : "none"};
      padding: 16px;
      font-size: 14px;
      font-weight: normal;
      text-align: left;
    }
  }
`;

export const Tbody = styled.tbody<TableProps>`
  tr {
    &:hover {
      cursor: pointer;
      background-color: #f9fafb;
    }
    td {
      padding: 21px;
      border: ${({ variant }) =>
        variant === "border" ? "1px solid #e2e2e3" : "none"};
      font-size: 14px;
      font-weight: normal;
      &.active {
        color: #21ba45;
      }
      &.inactive {
        color: #ee2737;
      }
    }
  }
`;

export const ImageContainer = styled.div`
  width: 150px;
  text-align: center;
  padding: 20px;
`;

export const ImageLogo = styled.img`
  max-width: 80%;
  height: auto;
`;

export const Desc = styled.img.attrs(({ src }) => ({
  src: CaretDownIconUrl,
}))`
  margin-left: 10px;
`;

export const Asc = styled.img.attrs(({ src }) => ({
  src: CaretDownIconUrl,
}))`
  margin-left: 10px;
  transform: rotate(180deg);
`;
