import Modal from "components/common/Modal";
import RequiredFieldIndicator from "components/common/RequiredFieldIndicator";
import RichTextEditor from "components/common/RichTextEditor";
import React, { forwardRef, Ref, useRef } from "react";
import Dropdown from "../common/Dropdown/Dropdown";
import { PromoRequest, PromoDetailResponse } from "utils/data/promo";
import {
  ModalContent,
  PromoTypeContainer,
  PromoDetailContainer,
  FieldLabel,
  FieldContainer,
  PromoNameInput,
  ActionButtons,
  CancelButton,
  SaveButton,
  ErrorText,
  PromoNameContainer,
} from "./style";
import { Formik, Form, Field, FieldProps, FormikProps } from "formik";
import * as Yup from "yup";

type PromoTypeOption = {
  value: string;
  label: string;
};

type FormValues = {
  promoType: PromoTypeOption | null;
  promoName: string;
  description: string;
};

type ModalBodyProps = {
  promoDetail: PromoDetailResponse;
  ref: Ref<FormikProps<FormValues>>;
};

const PromoFormSchema = Yup.object().shape({
  promoType: Yup.mixed().required("Please select a promo type"),
  promoName: Yup.string().required("Promo name cannot be empty"),
  description: Yup.string()
    .trim()
    .required("Promo description cannot be empty"),
});

const ModalBody: React.FC<ModalBodyProps> = forwardRef(
  (props, ref: Ref<FormikProps<FormValues>>) => {
    const promoTypes = [{ value: "OTHER_PROMOS", label: "Other Promos" }];

    const { promoDetail } = props;

    return (
      <ModalContent>
        <Formik
          innerRef={ref}
          initialValues={{
            promoType: promoDetail.id
              ? { value: promoDetail.promoType || "", label: "Other Promos" }
              : null,
            promoName: promoDetail.name,
            description: promoDetail.detailPromo[0],
          }}
          validationSchema={PromoFormSchema}
          onSubmit={(values) => {}}
        >
          {({ values, errors, isSubmitting }) => (
            <Form>
              <PromoTypeContainer>
                <FieldLabel data-testid="label-promo-type">
                  Promo Type <RequiredFieldIndicator />
                </FieldLabel>
                <FieldContainer>
                  <Field name="promoType">
                    {({ form, field, meta }: FieldProps<PromoTypeOption>) => {
                      return (
                        <Dropdown
                          placeholder="Select Promo Type"
                          options={promoTypes}
                          selectedOption={field.value}
                          handleChange={(option) =>
                            form.setFieldValue(field.name, option)
                          }
                          hasError={isSubmitting && !form.values.promoType}
                        />
                      );
                    }}
                  </Field>
                </FieldContainer>
                {/* TODO: change this using errors.promoType. Still don't know why can't detect errors for promoType */}
                {isSubmitting && !values.promoType && (
                  <ErrorText data-testid="error-type">
                    Please select a promo type
                  </ErrorText>
                )}
              </PromoTypeContainer>

              <PromoDetailContainer>
                {!values.promoType && <p>Please select a promo type</p>}
                {values.promoType && (
                  <>
                    <FieldLabel data-testid="label-promo-name">
                      Promo Name <RequiredFieldIndicator />
                    </FieldLabel>
                    <PromoNameContainer>
                      <Field name="promoName">
                        {({ form, field, meta }: FieldProps<string>) => (
                          <PromoNameInput
                            placeholder="Insert promo name"
                            onChange={(e) =>
                              form.setFieldValue(
                                field.name,
                                e.target.value.trimStart()
                              )
                            }
                            maxLength={30}
                            value={field.value}
                          />
                        )}
                      </Field>
                      {isSubmitting && errors.promoName && (
                        <ErrorText>{errors.promoName}</ErrorText>
                      )}
                    </PromoNameContainer>

                    <FieldLabel data-testid="label-description">
                      Promo Description <RequiredFieldIndicator />
                    </FieldLabel>
                    <Field name="description">
                      {({ form, field, meta }: FieldProps<string>) => (
                        <RichTextEditor
                          onChange={(markdown) =>
                            form.setFieldValue(field.name, markdown)
                          }
                          defaultValue={promoDetail?.detailPromo[0]}
                        />
                      )}
                    </Field>
                    {isSubmitting && errors.description && (
                      <ErrorText>{errors.description}</ErrorText>
                    )}
                  </>
                )}
              </PromoDetailContainer>
            </Form>
          )}
        </Formik>
      </ModalContent>
    );
  }
);

type FooterProps = {
  onClickCancel: () => void;
  onClickSubmit: () => void;
  promoId?: string;
};

const ModalFooter: React.FC<FooterProps> = (props) => {
  const { onClickCancel, onClickSubmit } = props;

  return (
    <ActionButtons>
      <CancelButton
        color="default"
        onClick={onClickCancel}
        data-testid="btn-cancel"
      >
        Cancel
      </CancelButton>
      <SaveButton
        color="primary"
        onClick={onClickSubmit}
        data-testid="btn-submit"
      >
        Save
      </SaveButton>
    </ActionButtons>
  );
};

type PromoFormModalProps = {
  onCancel: () => void;
  onSubmit: (values: PromoRequest, id: string) => void;
  promoDetail: PromoDetailResponse;
};

const PromoFormModal: React.FC<PromoFormModalProps> = (props) => {
  const { onCancel, onSubmit, promoDetail } = props;

  const formRef = useRef<FormikProps<FormValues>>(null);

  const handleSubmitForm = () => {
    const formValues = formRef.current?.values;

    formRef.current?.setSubmitting(true);

    if (
      formValues?.promoType &&
      formValues.promoName &&
      formValues.description
    ) {
      const { promoName, promoType, description } = formValues;
      const data = {
        active: promoDetail?.id ? promoDetail?.active : true,
        name: promoName.trim(),
        promoType: promoType.value,
        detailPromo: [description?.trim()],
        paymentCredentialIds: promoDetail?.id
          ? promoDetail.paymentCredentialIds
          : [],
      };

      if (promoDetail?.id) {
        // For update promo
        onSubmit(data, promoDetail.id);
      } else {
        // For create promo
        onSubmit(data, "");
      }
    }
  };

  return (
    <Modal
      title={!promoDetail?.id ? "Create New Promo" : "Editing Promo"}
      body={<ModalBody ref={formRef} promoDetail={promoDetail} />}
      footer={
        <ModalFooter
          onClickCancel={onCancel}
          onClickSubmit={handleSubmitForm}
        />
      }
      isMaxWidth
    />
  );
};

export default PromoFormModal;
