import React from "react";
import { useHistory } from "react-router-dom";
import { OrderDetail } from "utils/data/order";

import {
  FormContainer,
  LabelValueContainer,
  Label,
  Value,
  ClickAbleValue,
} from "./style";

type OrderBodyProps = {
  orderData: OrderDetail;
};

const OrderBody: React.FC<OrderBodyProps> = (props) => {
  const { orderData: values } = props;
  const history = useHistory();

  return (
    <FormContainer>
      <LabelValueContainer>
        <Label data-testid="label-gostore-id">Gostore ID</Label>
        <Value>{values?.gostoreOrderId}</Value>
      </LabelValueContainer>
      <LabelValueContainer>
        <Label>Shipper Tracking ID</Label>
        <ClickAbleValue
          onClick={() =>
            history.replace(
              `/shipping?trackingId=${values?.shipping.trackingId}`
            )
          }
        >
          {values?.shipping.trackingId}
        </ClickAbleValue>
      </LabelValueContainer>
      <LabelValueContainer>
        <Label>Creation Date</Label>
        <Value>{values?.createdAt}</Value>
      </LabelValueContainer>
      <LabelValueContainer>
        <Label>Customer Name</Label>
        <Value>{values?.customer.name}</Value>
      </LabelValueContainer>
      <LabelValueContainer>
        <Label>Customer Email</Label>
        <Value>{values?.customer.email}</Value>
      </LabelValueContainer>
      <LabelValueContainer>
        <Label>Customer Phone</Label>
        <Value>{values?.customer.phoneNumber}</Value>
      </LabelValueContainer>
      <LabelValueContainer>
        <Label>Shipper ID</Label>
        <Value>{values?.shipping.id}</Value>
      </LabelValueContainer>
      <LabelValueContainer>
        <Label>Payment ID</Label>
        <Value>{values?.payment.id}</Value>
      </LabelValueContainer>
      <LabelValueContainer>
        <Label>Payment Type</Label>
        <Value>{values?.payment.type}</Value>
      </LabelValueContainer>
      <LabelValueContainer>
        <Label>Payment Status</Label>
        <Value>{values?.payment.status}</Value>
      </LabelValueContainer>
      <LabelValueContainer>
        <Label>Order Status</Label>
        <Value>{values?.gostoreStatus}</Value>
      </LabelValueContainer>
      <LabelValueContainer>
        <Label>Total Price</Label>
        <Value>{values?.totalOrderPrice}</Value>
      </LabelValueContainer>
      <LabelValueContainer>
        <Label>Midtrans Status</Label>
        <Value>{values?.payment.midtransLatestTransaction}</Value>
      </LabelValueContainer>
      <LabelValueContainer>
        <Label>Order Acceptance Expire At</Label>
        <Value>{values?.orderAcceptanceExpiresAt}</Value>
      </LabelValueContainer>
      <LabelValueContainer>
        <Label>Request Pickup Expire At</Label>
        <Value>{values?.requestPickupExpireAt}</Value>
      </LabelValueContainer>
      <LabelValueContainer>
        <Label>Ready For Self Pickup Expire At</Label>
        <Value>{values?.readyForSelfPickupExpireAt}</Value>
      </LabelValueContainer>
      <LabelValueContainer>
        <Label>Payment Expire At</Label>
        <Value>{values?.paymentExpiredAt}</Value>
      </LabelValueContainer>
    </FormContainer>
  );
};

export default OrderBody;
