import styled from "styled-components";
import { default as ButtonDefault } from "components/Button";

export const ActionContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const Button = styled(ButtonDefault)`
  min-width: 142px;
`;

export const FormContainer = styled.div`
  padding: 24px 32px;
  border: 1px solid #dededf;
  box-sizing: border-box;
  border-radius: 4px;
  margin-top: 24px;
  display: flex;
  flex-wrap: wrap;
`;

export const Label = styled.p`
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  color: rgba(51, 51, 51, 0.87);
`;

export const Value = styled.p`
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  &.active {
    color: #21ba45;
  }
  &.inactive {
    color: #ee2737;
  }
`;

export const ClickAbleValue = styled.p`
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  cursor: pointer;
  text-decoration: underline;
  color: #0000ee;
`;

export const LabelValueContainer = styled.div`
  width: calc(100% / 3);
  box-sizing: border-box;
  padding: 10px;
`;

export const RefundButton = styled(Button)`
  margin-right: 16px;
`;
