import React from "react";

export const LoginModalContext = React.createContext({
  isShownModal: false,
  setIsShownModal: (value: boolean) => {},
});

const LoginModalContextProvider: React.FC = ({ children }) => {
  const [isShownModal, setIsShownModal] = React.useState(false);

  const value = { isShownModal, setIsShownModal };

  return (
    <LoginModalContext.Provider value={value}>
      {children}
    </LoginModalContext.Provider>
  );
};

export default LoginModalContextProvider;
