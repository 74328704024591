import styled, { css } from "styled-components";
import { colors } from "utils/theme";
import { InputProps } from "./Input";

export const Container = styled.div`
  width: 100%;
`;

export const DefaultInput = styled.input<Pick<InputProps, "errorMessage">>`
  width: 100%;
  outline: none;
  border-radius: 4px;
  padding: 10px 17px;
  box-sizing: border-box;
  border: 1px solid
    ${({ errorMessage }) => (errorMessage ? colors.error : colors.line)};
  &:focus {
    border: 1px solid ${colors.info};
  }
`;

export const Label = styled.p<Pick<InputProps, "required">>`
  margin: 0px;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 17px;
  color: ${colors.text};
  ${({ required }) =>
    required
      ? css`
          &:after {
            content: " *";
            color: ${colors.error};
          }
        `
      : null}
`;

export const Error = styled.p`
  margin: 0px;
  color: ${colors.error};
  margin-top: 8px;
  font-size: 12px;
  font-weight: 500;
`;
