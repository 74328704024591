import styled from "styled-components";

export const PageTitle = styled.p`
  font-size: 32px;
  line-height: 38px;
  color: #0a0000;
`;

export const ActionBar = styled.div`
  display: flex;
  justify-content: flex-end;
  button {
    margin-left: 16px;
  }
  margin-bottom: 40px;
`;
