import Modal from "components/common/Modal";
import React, { useCallback, useState } from "react";
import ModalFooter from "./ModalFooter";
import ModalBody from "./ModalBody";
import { submitCSV } from "apis/promo/mid-mapping";
import { toast } from "react-toastify";

type MapBusinessesModalProps = {
  onCancel: () => void;
};

const MapBusinessesModal: React.FC<MapBusinessesModalProps> = (props) => {
  const { onCancel } = props;

  const [isValidFile, setIsValidFile] = useState(false);
  const [taskId, setTaskId] = useState("");

  const handleFileChange = (taskId: string, status: boolean) => {
    setTaskId(taskId);
    setIsValidFile(status);
  };

  const handleSubmit = useCallback(async () => {
    if (isValidFile) {
      try {
        await submitCSV(taskId);
        toast.success("File successfully submitted!");
        onCancel();
      } catch {
        toast.error("System failure, please try again");
      }
    }
  }, [isValidFile, onCancel, taskId]);

  return (
    <Modal
      title="Map Businesses"
      body={<ModalBody onFileChange={handleFileChange} />}
      footer={
        <ModalFooter
          onCancel={onCancel}
          onSubmit={handleSubmit}
          isValidFile={isValidFile}
        />
      }
      isMaxWidth
    />
  );
};

export default MapBusinessesModal;
