import React, { useEffect } from "react";
import userReducer from "./reducer";
import { UserDispatch, UserState } from "./type";
import { getUser } from "utils/localStorage";

const defaultUserValue = {
  name: "",
  email: "",
} as UserState;

export const UserContext = React.createContext<{
  state: UserState;
  dispatch: UserDispatch;
}>({
  state: defaultUserValue,
  dispatch: () => {},
});

const UserProvider: React.FC = ({ children }) => {
  const [state, dispatch] = React.useReducer(userReducer, defaultUserValue);

  useEffect(() => {
    const userFromLocalStorage = getUser();
    if (userFromLocalStorage) {
      dispatch({
        type: "UPDATE_USER",
        payload: userFromLocalStorage,
        error: false,
      });
    }
  }, []);

  const value = { state, dispatch };

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

export default UserProvider;
