import React from "react";
import styled from "styled-components";

const Required = styled.div`
  color: #ee2737;
  margin-left: 5px;
`;

const RequiredFieldIndicator: React.FC = () => {
  return <Required>*</Required>;
};

export default RequiredFieldIndicator;
