import Modal from "components/common/Modal";

import { ShippingOrder } from "utils/data/shipping";

import ShippingBody from "./ShippingBody";
import ShippingFooter from "./ShippingFooter";

type ShippingDetailProps = {
  onClose: () => void;
  values: ShippingOrder;
};

export default function ShippingDetail(props: ShippingDetailProps) {
  const { onClose, values } = props;
  return (
    <Modal
      isMaxWidth
      data-testid="shipping-detail-title"
      title="Shipping Detail"
      body={<ShippingBody shippingData={values} />}
      footer={<ShippingFooter onClose={onClose} />}
    />
  );
}
