import styled from "styled-components";

export const InputForm = styled.div`
  input {
    border: 1px solid #e2e2e3;
    font-size: 12px;
    font-weight: 300;
    border-radius: 4px;
    cursor: pointer;
    padding: 10px;
    box-sizing: border-box;
    outline: none;
    width: 100%;
  }
`;
