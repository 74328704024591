import { FeatureFlagAction, State } from "./type";

const featureFlagReducer = (featureFlag: State, action: FeatureFlagAction) => {
  switch (action.type) {
    case "UPDATE_FEATURE_FLAG": {
      const { featureName, isActive } = action.payload;
      const features = {
        ...featureFlag.features,
        [featureName]: isActive,
      };
      return {
        features,
      };
    }
    default: {
      return featureFlag;
    }
  }
};

export default featureFlagReducer;
