import Modal from "components/common/Modal";
import { PromoDetailResponse } from "utils/data/promo";
import PromoBody from "./PromoBody";
import PromoFooter from "./PromoFooter";

type PromoDetailProps = {
  onClose: (isUpdateData: boolean) => void;
  promoData: PromoDetailResponse;
  onClickEdit: () => void;
  onClickAssignMID: () => void;
  onUpdateStatus: () => void;
};

export default function PromoDetail(props: PromoDetailProps) {
  const { onClose, promoData, onClickEdit, onClickAssignMID, onUpdateStatus } =
    props;

  const closeModal = () => {
    onClose(false);
  };

  return (
    <Modal
      isMaxWidth
      title="Promo Details"
      body={
        <PromoBody
          promoData={promoData}
          updateStatus={onUpdateStatus}
          onClickEdit={onClickEdit}
          onClickAssignMID={onClickAssignMID}
        />
      }
      footer={<PromoFooter closeModal={closeModal} />}
    />
  );
}
