import styled from "styled-components";
import { default as ButtonDefault } from "components/Button";

export const ActionContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const Button = styled(ButtonDefault)`
  min-width: 142px;
`;
