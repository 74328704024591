import React from "react";
import featureFlagReducer from "./reducer";
import { FeatureFlagDispatch, State } from "./type";

const defaultFeatureFlagValue = {
  features: {
    OrderPage: false,
  },
} as State;

export const FeatureFlagContext = React.createContext<{
  state: State;
  dispatch: FeatureFlagDispatch;
}>({
  state: defaultFeatureFlagValue,
  dispatch: () => {},
});

const FeatureFlagProvider: React.FC = ({ children }) => {
  const [state, dispatch] = React.useReducer(
    featureFlagReducer,
    defaultFeatureFlagValue
  );

  const value = { state, dispatch };

  return (
    <FeatureFlagContext.Provider value={value}>
      {children}
    </FeatureFlagContext.Provider>
  );
};

export default FeatureFlagProvider;
