import { PaginationContainer } from "./style";
import ReactPaginate from "react-paginate";
interface PaginationProps {
  gotoPage: (e: number) => void;
  totalPage: number;
  pageIndex: number;
}

export default function Pagination(props: PaginationProps) {
  const { gotoPage, totalPage, pageIndex } = props;
  const onPageChange = (page) => {
    gotoPage(page.selected);
  };

  return (
    <PaginationContainer>
      <button
        className="prev"
        onClick={() => gotoPage(0)}
        disabled={pageIndex === 0}
      >
        {"<<"}
      </button>
      <ReactPaginate
        previousLabel="<"
        nextLabel=">"
        breakLabel={"..."}
        breakClassName={"break-me"}
        pageCount={totalPage}
        marginPagesDisplayed={3}
        pageRangeDisplayed={3}
        onPageChange={onPageChange}
        containerClassName={"pagination"}
        activeClassName={"active"}
        forcePage={pageIndex}
      />
      <button
        className="next"
        onClick={() => gotoPage(totalPage - 1)}
        disabled={totalPage - 1 === pageIndex}
      >
        {">>"}
      </button>
    </PaginationContainer>
  );
}
