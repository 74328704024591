import React from "react";
import {
  BoldValue,
  ShippingStatusBodyContainer,
  ShippingStatusBodyReminder,
  ShippingStatusBodyMessage,
} from "./style";

type ShippingStatusBodyProps = {
  gostoreId: string;
  shippingStatus: string;
};

const ShippingStatusBody: React.FC<ShippingStatusBodyProps> = (props) => {
  const { gostoreId, shippingStatus } = props;
  return (
    <ShippingStatusBodyContainer>
      <ShippingStatusBodyReminder>
        Updating status cannot be undone and the actor's information will be
        recorded.
      </ShippingStatusBodyReminder>
      <ShippingStatusBodyMessage>
        <BoldValue>{gostoreId}</BoldValue> status will be updated to{" "}
        <BoldValue>{shippingStatus}</BoldValue>
      </ShippingStatusBodyMessage>
    </ShippingStatusBodyContainer>
  );
};

export default ShippingStatusBody;
