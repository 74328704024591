import axios from "utils/axiosInstance";
import { DateTime } from "luxon";
import {
  ShippingOrderList,
  RawShippingOrderList,
  ShippingOrder,
  ShippingParams,
  ShippingStatusRequest,
} from "utils/data/shipping";

function shippingOrderListAPIResponseTransformer(
  response: RawShippingOrderList
): ShippingOrderList {
  return {
    ...response,
    data: response?.data.map<ShippingOrder>((rawShippingOrder) => {
      const isDelivery = rawShippingOrder.tracking?.length > 0;
      return {
        ...rawShippingOrder,
        insurance: rawShippingOrder.useInsurance ? "Yes" : "No",
        status: rawShippingOrder.active ? "Active" : "Inactive",
        gostoreStatus: isDelivery
          ? rawShippingOrder.tracking[rawShippingOrder.tracking.length - 1]
              .trackingStatus
          : "Self Pickup",
        shipperStatus: isDelivery
          ? rawShippingOrder.tracking[rawShippingOrder.tracking.length - 1]
              .shipperStatus.name
          : "Self Pickup",
        creationDate: DateTime.fromISO(
          rawShippingOrder.creationDate
        ).toLocaleString(),
        lastUpdatedDate: DateTime.fromISO(
          rawShippingOrder.lastUpdatedDate
        ).toLocaleString(),
      };
    }),
  };
}

export const getShippingOrderStatusAPI = () => {
  return axios.get("/internal-tool/v1/shipping/status", {
    withCredentials: true,
  });
};

export const updateShippingStatusAPI = (request: ShippingStatusRequest) => {
  return axios.patch("/internal-tool/v1/shipping/orders", request, {
    withCredentials: true,
  });
};

export const getShippingOrderListAPI = (
  fromDate: string,
  toDate: string,
  params: ShippingParams,
  page: number,
  size: number
) => {
  return axios.get("/internal-tool/v1/shipping/orders", {
    withCredentials: true,
    params: {
      fromDate,
      toDate,
      ...params,
      page,
      size,
    },
    responseType: "json",
    transformResponse: shippingOrderListAPIResponseTransformer,
  });
};
