import Button from "components/Button";
import Modal from "components/common/Modal";
import React from "react";
import styled from "styled-components";

const ModalFooter = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const CancelButton = styled(Button)`
  padding: 10px 40px;
  margin-right: 16px;
`;

const ApproveButton = styled(Button)`
  padding: 10px 40px;
`;

type Props = {
  isActivate: boolean;
  promoId: string;
  onClickCancel: () => void;
  onApprove: () => void;
};

const UpdateStatusPromoModal: React.FC<Props> = (props) => {
  const { isActivate, promoId, onClickCancel, onApprove } = props;

  const title = isActivate ? "Activate" : "Deactivate";
  return (
    <Modal
      title={`${title} Promo?`}
      body={
        <div>
          Are you sure to {title.toLocaleLowerCase()} the promo{" "}
          <strong>{promoId}</strong>?
        </div>
      }
      footer={
        <ModalFooter>
          <CancelButton
            color="default"
            data-testid="btn-cancel"
            onClick={onClickCancel}
          >
            Cancel
          </CancelButton>
          <ApproveButton
            color={isActivate ? "primary" : "danger"}
            data-testid="btn-approve"
            onClick={onApprove}
          >
            {title}
          </ApproveButton>
        </ModalFooter>
      }
      isMaxWidth
    />
  );
};

export default UpdateStatusPromoModal;
