const colors = {
  success: "#0CBC3D",
  error: "#EE2737",
  info: "#0F94FF",
  text: "#333333",
  line: "#DEDEDF",
  button: {
    primary: {
      main: "#0CBC3D",
      dark: "#0a9631",
      disable: "rgba(12, 188, 62, 0.33)",
    },
    default: {
      main: "#FFFFFF",
      dark: "#d8d7d7",
      disable: "rgba(216, 215, 215, 0.33)",
    },
    danger: {
      main: "#EE2737",
      dark: "#ad1f1f",
      disable: "rgba(219, 40, 40, 0.33)",
    },
  },
};

export default colors;
