import React from "react";
import { UserContext } from "stores/user";

import { SidebarProps } from "./props";
import {
  SidebarContainer,
  SidebarItem,
  SidebarItemList,
  StyledLink,
} from "./style";

const Sidebar: React.FC<SidebarProps> = (props) => {
  const { items, isActive } = props;
  const { state } = React.useContext(UserContext);

  return (
    <>
      {state.name && (
        <SidebarContainer active={isActive}>
          <SidebarItemList>
            {items
              .filter((item) => item.isSidebarItem)
              .map((item) => (
                <StyledLink key={item.path} to={item.path}>
                  <SidebarItem role="SidebarItem">{item.name}</SidebarItem>
                </StyledLink>
              ))}
          </SidebarItemList>
        </SidebarContainer>
      )}
    </>
  );
};
export default Sidebar;
