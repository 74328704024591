import React from "react";
import { ActionButtons, CancelButton, SubmitButton } from "./style";

type ModalFooterProps = {
  onCancel: () => void;
  onSubmit: () => void;
  isValidFile: boolean;
};

const ModalFooter: React.FC<ModalFooterProps> = (props) => {
  const { onCancel, onSubmit, isValidFile } = props;
  return (
    <ActionButtons>
      <CancelButton color="default" onClick={onCancel} data-testid="btn-cancel">
        Cancel
      </CancelButton>
      <SubmitButton
        color="primary"
        onClick={onSubmit}
        data-testid="btn-submit"
        disabled={!isValidFile}
      >
        Submit
      </SubmitButton>
    </ActionButtons>
  );
};

export default ModalFooter;
