import Modal from "components/common/Modal";
import { ShippingOrder, ShippingStatusRequest } from "utils/data/shipping";
import ShippingStatusBody from "./ShippingStatusBody";
import ShippingStatusFooter from "./ShippingStatusFooter";

type ShippingStatusModalProps = {
  onClose: () => void;
  onSubmit: (values: ShippingOrder) => void;
  shippingData: ShippingOrder;
  statusRequest: ShippingStatusRequest;
};

const ShippingStatusModal: React.FC<ShippingStatusModalProps> = (props) => {
  const { onClose, onSubmit, shippingData, statusRequest } = props;
  return (
    <Modal
      title="Are you sure?"
      body={
        <ShippingStatusBody
          gostoreId={shippingData.gostoreOrderId}
          shippingStatus={statusRequest.status.name}
        />
      }
      footer={
        <ShippingStatusFooter
          onClose={onClose}
          onSubmit={onSubmit}
          statusRequest={statusRequest}
        />
      }
    />
  );
};

export default ShippingStatusModal;
