import Modal from "components/common/Modal";

import { OrderDetail } from "utils/data/order";
import OrderBody from "./OrderBody";
import OrderFooter from "./OrderDetailFooter";

type OrderDetailModalProps = {
  onClose: () => void;
  values: OrderDetail;
};

export default function OrderDetailModal(props: OrderDetailModalProps) {
  const { onClose, values } = props;
  return (
    <Modal
      isMaxWidth
      title="Order Detail"
      body={<OrderBody orderData={values} />}
      footer={<OrderFooter onClose={onClose} orderData={values} />}
    />
  );
}
