import React, { useState, useCallback, useEffect } from "react";
import { DateTime } from "luxon";
import { toast } from "react-toastify";

import Button from "components/Button";
import Dropdown from "../common/Dropdown/Dropdown";
import Datepicker from "../common/Datepicker/Datepicker";
import ShippingForm from "components/ShippingForm/ShippingForm";
import columns from "./columns";

import {
  ShippingPageTitle as OrderPageTitle,
  ContentContainer,
  DatepickerContainer,
  DropDownContainer,
  InputFormContainer,
  ButtonContainer,
} from "./style";
import Table from "components/common/Table";
import { getOrders } from "apis/order";
import OrderDetailModal from "components/OrderDetailModal";
import { OrderDetail, OrderSearchParam } from "utils/data/order";

type FilterTypeOption = {
  value: string;
  label: string;
};

const OrderPageContent: React.FC = () => {
  const [orderList, setOrderList] = useState<OrderDetail[]>([]);

  const [currentPage, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [totalPage, setTotalPage] = useState(0);
  const [shouldResetPage, setShouldRefreshPage] = useState(false);
  const [showOrderDetailModal, setShowOrderDetailModal] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState<OrderDetail>(
    {} as OrderDetail
  );

  const dateFormat = "yyyy-MM-dd";

  const defaultFromDate = DateTime.now()
    .minus({ days: 30 })
    .startOf("day")
    .toISO();
  const [fromDate, setFromDate] = useState(defaultFromDate);
  const [toDate, setToDate] = useState(DateTime.now().toISO());
  const [filterInput, setFilterInput] = useState("");
  const [options, setOptions] = useState<OrderSearchParam>({
    orderId: "",
    customerEmail: "",
    orderStatus: "",
  });

  const filterParams: FilterTypeOption[] = [
    { label: "Gostore ID", value: "orderId" },
    { label: "Customer Email", value: "customerEmail" },
    { label: "Order Status", value: "orderStatus" },
  ];

  const [filterType, setFilterType] = useState<FilterTypeOption | null>(
    filterParams[0]
  );

  const searchByParam = () => {
    setShouldRefreshPage(!shouldResetPage); //reset pagination to first page when searching fresh data
    callGoStoreOrders(fromDate, toDate, options, currentPage, pageSize);
  };

  const handleFromDatePickerChange = useCallback(
    (date: string) => {
      setFromDate(DateTime.fromFormat(date, dateFormat).toISO());
    },
    [dateFormat]
  );

  const handleToDatePickerChange = useCallback(
    (date: string) => {
      setToDate(DateTime.fromFormat(date, dateFormat).toISO());
    },
    [dateFormat]
  );

  const onRowClick = (idx: number) => {
    setShowOrderDetailModal(true);
    setSelectedOrder(orderList[idx]);
  };

  const onCloseModal = () => {
    callGoStoreOrders(fromDate, toDate, options, currentPage, pageSize);
    setShowOrderDetailModal(false);
  };

  const callGoStoreOrders = useCallback(
    (
      fromDate: string,
      toDate: string,
      options: OrderSearchParam,
      currentPage: number,
      pageSize: number
    ) => {
      getOrders({ fromDate, toDate, currentPage, pageSize, params: options })
        .then((response) => {
          setOrderList(response.data.data);
          setTotalPage(response.data.totalPage);
        })
        .catch(() => toast.error("System failure, please try again"));
    },
    []
  );

  useEffect(() => {
    const opt: OrderSearchParam = {
      orderId: "",
      customerEmail: "",
      orderStatus: "",
    };

    if (filterType?.value) {
      let filterInputValue = filterInput;
      if (filterType?.value === "orderStatus") {
        filterInputValue = filterInput.toUpperCase();
      }
      opt[filterType?.value] = filterInputValue;
    }

    setOptions(opt);
  }, [filterInput, filterType]);

  useEffect(() => {
    callGoStoreOrders(fromDate, toDate, options, currentPage, pageSize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, pageSize, callGoStoreOrders]);

  return (
    <>
      <ContentContainer>
        <OrderPageTitle>GoStore Order</OrderPageTitle>
        <DatepickerContainer>
          <Datepicker
            data-testid="input-start-date"
            value={DateTime.fromISO(fromDate).toFormat(dateFormat)}
            onDateChange={handleFromDatePickerChange}
          />
          <Datepicker
            data-testid="input-end-date"
            value={DateTime.fromISO(toDate).toFormat(dateFormat)}
            onDateChange={handleToDatePickerChange}
          />
        </DatepickerContainer>
        <DropDownContainer>
          <Dropdown
            placeholder="Select Filter Type"
            options={filterParams}
            selectedOption={filterType}
            handleChange={setFilterType}
          />
        </DropDownContainer>
        <InputFormContainer>
          <ShippingForm
            placeholder={`Search by ${filterType?.label}`}
            value={filterInput}
            onInputChange={setFilterInput}
          />
        </InputFormContainer>

        <ButtonContainer>
          <Button aria-label="search-button" onClick={searchByParam}>
            Search
          </Button>
        </ButtonContainer>
      </ContentContainer>

      <Table
        data={orderList}
        columns={columns}
        shouldResetPage={shouldResetPage}
        onPageIndexChange={setPage}
        onPageSizeChange={setPageSize}
        onRowClick={onRowClick}
        totalPage={totalPage}
      />

      {showOrderDetailModal && (
        <OrderDetailModal onClose={onCloseModal} values={selectedOrder} />
      )}
    </>
  );
};

export default OrderPageContent;
