import axios from "utils/axiosInstance";
import { LoginRequest } from "utils/data/user";

export const logout = () => {
  return axios.delete<void>("/internal-tool/v1/sessions");
};

export const login = (request: LoginRequest) => {
  return axios.post<void>("/internal-tool/v1/sessions", request);
};
