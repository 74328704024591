import { bulkPaymentRefund } from "apis/payment";
import Button from "components/Button";
import React from "react";
import { toast } from "react-toastify";
import { ActionContainer, ConfirmButton } from "./style";

type OrderDetailFooterProps = {
  onClose: () => void;
  onOrderDetailClose: () => void;
  orderId: string;
};

const OrderRefundFooter: React.FC<OrderDetailFooterProps> = (props) => {
  const { onClose, orderId, onOrderDetailClose } = props;
  const proceedRefund = async () => {
    try {
      await bulkPaymentRefund({ order_ids: [orderId] });
      toast.success("Refund successfully done!");
      onClose();
      onOrderDetailClose();
    } catch (e) {
      toast.error("Refund failed");
    }
  };
  return (
    <ActionContainer>
      <Button data-testid="btn-refund-cancel" color="default" onClick={onClose}>
        Close
      </Button>
      <ConfirmButton
        data-testid="btn-refund-confirm"
        color="default"
        onClick={proceedRefund}
      >
        Confirm
      </ConfirmButton>
    </ActionContainer>
  );
};

export default OrderRefundFooter;
