import styled from "styled-components";

export const DashboardContainer = styled.div`
  display: flex;
`;

export const ContentContainer = styled.div`
  padding: 40px 60px;
  width: 100%;
  height: 100vh;
  overflow-y: auto;
  padding-bottom: 40px;
`;
