import styled from "styled-components";
import { default as ButtonDefault } from "components/Button";

export const TrackingTitleContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
`;

export const Value = styled.p`
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  &.active {
    color: #21ba45;
  }
  &.inactive {
    color: #ee2737;
  }
`;

export const UpdateFormContainer = styled.div`
  padding: 24px 32px;
  border: 1px solid #dededf;
  box-sizing: border-box;
  border-radius: 4px;
  margin-top: 24px;
`;

export const UpdateFormActionContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-top: 10px;
`;

export const CancelButton = styled(ButtonDefault)`
  padding: 10px 40px;
  margin-right: 16px;
`;

export const UpdateButton = styled(ButtonDefault)`
  padding: 10px 40px;
`;
