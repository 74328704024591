import Button from "components/Button";
import { ActionContainer } from "./style";

type ShippingFooterProps = {
  onClose: () => void;
};

const ShippingFooter: React.FC<ShippingFooterProps> = (props) => {
  const { onClose } = props;
  return (
    <>
      <ActionContainer>
        <Button data-testid="button-close" color="default" onClick={onClose}>
          Close
        </Button>
      </ActionContainer>
    </>
  );
};

export default ShippingFooter;
