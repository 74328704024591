import { MockApi } from "mocks/type";

const postPaymentRefund: MockApi = {
  endpoint: "/internal-tool/v1/order-payment/refund",
  method: "post",
  behaviour: () => {},
  response: {
    data: [
      {
        orderId: "",
        status: "",
        message: "",
      },
    ],
    status: 200,
  },
};

const paymentApis = [postPaymentRefund];

export default paymentApis;
