import React from "react";
import { UnauthorizedPageContact, UnauthorizedPageContainer } from "./style";

const UnauthorizedPage = () => (
  <UnauthorizedPageContainer>
    You do not have access to view this page. Please contact{" "}
    <UnauthorizedPageContact href="mailto:gtf-omg-coo-proeng@gojek.com">
      liberty-team@gojek.com
    </UnauthorizedPageContact>{" "}
    to request for access.
  </UnauthorizedPageContainer>
);

export default UnauthorizedPage;
