import styled from "styled-components";

export const NavbarContainer = styled.div`
  background-color: #2d3b4a;
  color: white;
  display: flex;
  padding: 16px;
  justify-content: space-between;
`;

export const NavigationDrawer = styled.img`
  margin-right: 120px;
`;

export const NavbarLeft = styled.div`
  display: flex;
`;

export const NavbarRight = styled.div`
  display: flex;
  justify-content: space-around;
`;

export const NavbarItem = styled.div`
  margin-right: 28px;
  cursor: pointer;
`;
