import Modal from "components/common/Modal";
import { OrderDetail } from "utils/data/order";

import OrderRefundBody from "./OrderRefundBody";
import OrderRefundFooter from "./OrderRefundFooter";

type OrderRefundModalProps = {
  onClose: () => void;
  orderData: OrderDetail;
  onOrderDetailClose: () => void;
};

export default function OrderRefundModal(props: OrderRefundModalProps) {
  const { onClose, orderData, onOrderDetailClose } = props;
  console.log(orderData);
  return (
    <Modal
      title="Are you sure you want to refund this order?"
      body={<OrderRefundBody totalRefund={orderData.totalOrderPrice} />}
      footer={
        <OrderRefundFooter
          onClose={onClose}
          orderId={orderData.gostoreOrderId}
          onOrderDetailClose={onOrderDetailClose}
        />
      }
    />
  );
}
