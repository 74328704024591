import React, { useState } from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import Toast from "components/Toast";
import Navbar from "components/common/Navbar";
import Sidebar from "components/common/Sidebar";

import { ContentContainer, DashboardContainer } from "./style";

import RouterList from "./router";
import RouteWithSubRoutes from "components/common/RouteWithSubRoutes";
import UserProvider from "stores/user";
import LoginPageModal from "components/LoginPageModal";
import LoginModalContextProvider from "stores/login-modal";

const App = () => {
  const [isSidebarActive, setIsSidebarActive] = useState(true);

  return (
    <UserProvider>
      <LoginModalContextProvider>
        <Navbar
          isSidebarActive={isSidebarActive}
          onSidebarStatusChange={setIsSidebarActive}
        />
        <LoginPageModal />
      </LoginModalContextProvider>
      <DashboardContainer>
        <Sidebar items={RouterList} isActive={isSidebarActive} />

        <ContentContainer>
          <Switch>
            {RouterList.map((sidebar) => (
              <RouteWithSubRoutes key={sidebar.path} {...sidebar} />
            ))}
            <Route exact path="/">
              <Redirect to="/promo" />
            </Route>
          </Switch>
        </ContentContainer>
      </DashboardContainer>
      <Toast />
    </UserProvider>
  );
};

export default App;
