import { uploadCSV } from "apis/promo/mid-mapping";
import React, { useState } from "react";
import { toast } from "react-toastify";
import failedIconUrl from "./failed-icon.svg";
import loadingIconUrl from "./loading-icon.svg";
import {
  ErrorMessage,
  InputFile,
  ModalContent,
  StateIcon,
  UploadButton,
  UploadButtonContainer,
  UploadIcon,
  UploadInfo,
} from "./style";
import successIconUrl from "./success-icon.svg";
import uploadIconUrl from "./upload-icon.svg";

type ModalBodyProps = {
  onFileChange: (taskId: string, isValid: boolean) => void;
};

const ModalBody = (props: ModalBodyProps) => {
  const { onFileChange } = props;
  const [buttonName, setButtonName] = useState("Upload");
  const [errorMessage, setErrorMessage] = useState("");
  const [isValidFile, setIsValidFile] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [isPristine, setIsPristine] = useState(true);

  const onUploadFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files !== null) {
      const file = e.target.files?.[0];
      if (file) {
        const { name } = file;
        setIsPristine(false);
        setIsUploading(true);
        setButtonName(name);

        const data = new FormData();
        data.append("mid-mapping", file);
        try {
          const result = await uploadCSV(data);
          let errorMessage = "";
          setIsValidFile(true);
          setIsUploading(false);

          onFileChange(result.data.taskId, true);
          if (result.data?.message) {
            errorMessage = result.data.message;
          }

          setErrorMessage(errorMessage);
          toast.success("File successfully uploaded!");
        } catch (error) {
          if (error.response.data.errors) {
            setErrorMessage(error.response.data.errors[0]);
          } else {
            toast.error("System failure, please try again.");
          }
          setIsValidFile(false);
          setIsUploading(false);

          onFileChange("", false);
        }
      } else {
        setIsUploading(false);
      }
    }
  };

  const isFileSelected = isValidFile || errorMessage.length > 0;

  const renderIconStatus = () => {
    if (isPristine) {
      return null;
    }
    if (isUploading) {
      return <StateIcon src={loadingIconUrl} alt="Loading Icon" />;
    } else if (isValidFile) {
      return <StateIcon src={successIconUrl} alt="Success Icon" />;
    } else {
      return <StateIcon src={failedIconUrl} alt="Failed Icon" />;
    }
  };

  return (
    <ModalContent>
      <UploadInfo>Upload .csv file to map businesses</UploadInfo>
      <UploadButtonContainer>
        <div>
          <UploadButton>
            {!isFileSelected && (
              <UploadIcon src={uploadIconUrl} alt="Upload Icon" />
            )}
            {buttonName}
          </UploadButton>
          <InputFile
            type="file"
            id="upload-btn"
            accept=".csv"
            onChange={onUploadFileChange}
          />
        </div>
        {renderIconStatus()}
      </UploadButtonContainer>
      <ErrorMessage>{errorMessage}</ErrorMessage>
    </ModalContent>
  );
};

export default ModalBody;
