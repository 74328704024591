import { updateShippingStatusAPI } from "apis/shipping";
import Button from "components/Button";
import React from "react";
import { ShippingOrder, ShippingStatusRequest } from "utils/data/shipping";
import { ActionContainer, ConfirmButton } from "./style";
import { toast } from "react-toastify";

type OrderDetailFooterProps = {
  onClose: () => void;
  onSubmit: (values: ShippingOrder) => void;
  statusRequest: ShippingStatusRequest;
};

const OrderRefundFooter: React.FC<OrderDetailFooterProps> = (props) => {
  const { onClose, onSubmit, statusRequest } = props;
  const updateStatus = () => {
    updateShippingStatusAPI(statusRequest)
      .then((response) => {
        toast.success("Status successfully updated!");
        onSubmit(response.data.data);
        onClose();
      })
      .catch(() => toast.error("System failure, please try agaIn."));
  };

  return (
    <ActionContainer>
      <Button data-testid="btn-refund-cancel" color="default" onClick={onClose}>
        Close
      </Button>
      <ConfirmButton
        data-testid="btn-update-confirm"
        color="default"
        onClick={updateStatus}
      >
        Confirm
      </ConfirmButton>
    </ActionContainer>
  );
};

export default OrderRefundFooter;
