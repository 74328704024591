import Modal from "components/common/Modal";
import React from "react";
import Body from "./Body";
import Footer from "./Footer";

type ChangeLogModalProps = {
  onClose: () => void;
  auditLogParam: {
    actions: string;
    size: number;
  };
  dispatchType: string;
  auditLogContext: any;
};

const ChangeLogModal: React.FC<ChangeLogModalProps> = (props) => {
  const { auditLogParam, dispatchType, auditLogContext, onClose } = props;

  return (
    <Modal
      title="Change Logs"
      body={
        <Body
          auditLogParam={auditLogParam}
          dispatchType={dispatchType}
          auditLogContext={auditLogContext}
        />
      }
      footer={<Footer onClose={onClose} />}
      isMaxWidth
    />
  );
};

export default ChangeLogModal;
