const columns = [
  {
    Header: "Outlet ID",
    accessor: "outletId",
  },
  {
    Header: "Customer Email",
    accessor: "customer.email",
  },
  {
    Header: "Customer Phone",
    accessor: "customer.phoneNumber",
  },
  {
    Header: "Order ID",
    accessor: "gostoreOrderId",
  },
  {
    Header: "Shipper ID",
    accessor: "shipping.trackingId",
  },
  {
    Header: "Order Status",
    accessor: "gostoreStatus",
  },
  {
    Header: "Payment ID",
    accessor: "payment.id",
  },
  {
    Header: "Payment Status",
    accessor: "payment.status",
  },
  {
    Header: "Creation Date",
    accessor: "createdAt",
  },
];

export default columns;
