import styled from "styled-components";
import { default as ButtonDefault } from "components/Button";
import colors from "utils/colors";

export const ActionContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const Button = styled(ButtonDefault)`
  min-width: 142px;
`;

export const FormContainer = styled.div`
  padding: 24px 32px;
  border: 1px solid #dededf;
  box-sizing: border-box;
  border-radius: 4px;
  margin-top: 24px;
  display: flex;
  flex-wrap: wrap;
`;

export const Label = styled.p`
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  color: rgba(51, 51, 51, 0.87);
`;

export const Value = styled.p`
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  &.active {
    color: #21ba45;
  }
  &.inactive {
    color: #ee2737;
  }
`;

export const LabelValueContainer = styled.div`
  width: calc(100% / 4);
  box-sizing: border-box;
  padding: 10px;
`;

export const LabelTrackingItem = styled.div`
  color: rgb(32, 32, 32);
  margin: 5px 0px;
  font-size: 15px;
  font-weight: 400;
`;

export const ValueTrackingItem = styled.div`
  color: rgb(173, 173, 173);
  font-size: 14px;
`;

export const TrackingFormContainer = styled.div`
  padding: 24px 32px;
  border: 1px solid #dededf;
  box-sizing: border-box;
  border-radius: 4px;
  margin-top: 24px;
`;

export const TrackingStatusItemContainer = styled.div<{
  status: string;
  lastIndex: boolean;
}>`
  display: grid;
  gap: 5px;
  position: relative;
  grid-template-columns: 30px 1fr;
  word-break: break-word;
  margin-bottom: 20px;

  :before {
    content: "";
    position: absolute;
    left: 9px;
    top: 20px;
    height: 100%;
    border-right: 2px dashed;
    color: ${(props) => {
      if (props.lastIndex) {
        return "#ffffff";
      }

      const statusColor = {
        item_delivered: colors.success,
        canceled: colors.error,
      };
      return statusColor[props.status] || "#d8d7d7";
    }};
  }
`;

export const TrackingStatusIcon = styled.div<{ status: string }>`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: ${(props) => {
    const statusColor = {
      item_delivered: colors.success,
      canceled: colors.error,
    };
    return statusColor[props.status] || "#d8d7d7";
  }};
`;

export const TrackingStatusDetail = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 10px;
`;

export const TrackingDetailTitle = styled.h4``;

export const TrackingStatusContent = styled.div``;

export const TrackingHeaderContainer = styled.div`
  display: grid;
  grid-template-columns: 30px 1fr 1fr 1fr;
  gap: 5px;
`;

export const ShippingLogo = styled.img`
  max-width: 50%;
  height: auto;
`;
