import React, { useEffect } from "react";
import { Column, usePagination, useTable } from "react-table";
import Pagination from "./Pagination";
import {
  ImageContainer,
  ImageLogo,
  TableContainer,
  Tbody,
  Thead,
} from "./style";

export interface TableProps {
  columns: Column[];
  data: Array<any>;
  onPageIndexChange: (payload: number) => void;
  onPageSizeChange: (payload: number) => void;
  onRowClick?: (idx: number) => void;
  variant?: "border";
  totalPage?: number;
  shouldResetPage?: boolean;
  pagination?: boolean;
}

export default function Table(props: TableProps) {
  const {
    columns,
    data,
    onPageIndexChange,
    onPageSizeChange,
    variant,
    totalPage = 0,
    onRowClick = () => {},
    shouldResetPage,
    pagination = true,
  } = props;
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    gotoPage,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 },
      manualPagination: true,
      pageCount: totalPage,
    },
    usePagination
  );

  useEffect(() => {
    gotoPage(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldResetPage]);

  useEffect(() => {
    onPageIndexChange(pageIndex);
    onPageSizeChange(pageSize);
  }, [onPageIndexChange, onPageSizeChange, pageIndex, pageSize]);

  return (
    <>
      <TableContainer {...getTableProps()}>
        <Thead variant={variant}>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()} data-testid="head-columns">
                  {column.render("Header")}
                </th>
              ))}
            </tr>
          ))}
        </Thead>
        <Tbody {...getTableBodyProps()} variant={variant}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <tr
                {...row.getRowProps()}
                data-testid="table-rows"
                onClick={() => onRowClick(row.index)}
              >
                {row.cells.map((cell) => {
                  return (
                    <td
                      {...cell.getCellProps()}
                      className={
                        cell.column.id === "status"
                          ? cell?.value?.toLowerCase()
                          : null
                      }
                      data-testid={cell.column.Header}
                    >
                      {cell.column.id === "img" ? (
                        <ImageContainer>
                          <ImageLogo src={cell?.value} alt="" />
                        </ImageContainer>
                      ) : (
                        cell.render("Cell")
                      )}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </Tbody>
      </TableContainer>
      {pagination && (
        <Pagination
          gotoPage={gotoPage}
          totalPage={totalPage}
          pageIndex={pageIndex}
        />
      )}
    </>
  );
}
