import axios from "utils/axiosInstance";
import { GetOrdersParam } from "./type";

export const getOrders = (getOrderParam: GetOrdersParam) => {
  const { fromDate, toDate, currentPage, pageSize, params } = getOrderParam;
  return axios.get("/internal-tool/v1/order-payment/list", {
    params: {
      fromDate,
      toDate,
      ...params,
      currentPage,
      pageSize,
    },
  });
};
