import Checkbox from "components/common/Checkbox";
import Modal from "components/common/Modal";
import React, { useState } from "react";
import { MerchantIdType } from "utils/data/promo";
import {
  ModalContent,
  SelectedPromoContainer,
  SelectMIDContainer,
  LabelSelectMID,
  OptionsContainer,
  ActionButtons,
  BackButton,
  SaveButton,
} from "./style";

type ModalBodyProps = {
  promoName: string;
  currentMerchantIds: MerchantIdType[];
  handleChangeCheckbox: (
    isChecked: boolean,
    selectedMerchantId: MerchantIdType
  ) => void;
};

const ModalBody: React.FC<ModalBodyProps> = (props) => {
  const { promoName, currentMerchantIds, handleChangeCheckbox } = props;

  return (
    <ModalContent>
      <SelectedPromoContainer>
        <strong>Selected Promo: </strong>
        {promoName}
      </SelectedPromoContainer>
      <SelectMIDContainer>
        <LabelSelectMID>Select MID</LabelSelectMID>
        <OptionsContainer>
          {currentMerchantIds.map((option) => {
            return (
              <Checkbox
                key={option.merchantId}
                label={option.merchantId}
                isChecked={option.enabled}
                handleChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  handleChangeCheckbox(event.target.checked, option)
                }
              />
            );
          })}
        </OptionsContainer>
      </SelectMIDContainer>
    </ModalContent>
  );
};

type ModalFooterProps = {
  onClickBack: () => void;
  onClickSave: () => void;
  isDisabled: boolean;
};

const ModalFooter: React.FC<ModalFooterProps> = (props) => {
  const { onClickBack, onClickSave, isDisabled } = props;
  return (
    <ActionButtons>
      <BackButton color="default" onClick={onClickBack} data-testid="btn-back">
        Back
      </BackButton>
      <SaveButton
        color="primary"
        onClick={onClickSave}
        data-testid="btn-save"
        disabled={isDisabled}
      >
        Save
      </SaveButton>
    </ActionButtons>
  );
};

type AssignPromoProps = {
  onClickBack: () => void;
  onClickSave: (currentMerchantIds: MerchantIdType[]) => void;
  promoName: string;
  merchantIds: MerchantIdType[];
};

const AssignPromoModal: React.FC<AssignPromoProps> = (props) => {
  const { onClickBack, onClickSave, promoName, merchantIds } = props;
  const [currentMerchantIds, setCurrentMerchantIds] = useState(merchantIds);

  const handleChangeCheckbox = (isChecked: boolean, option: MerchantIdType) => {
    const newCurrentMerchantIds = currentMerchantIds.map((currentMid) => {
      let selectedMid = currentMid;
      if (currentMid.merchantId === option.merchantId) {
        selectedMid = {
          ...currentMid,
          enabled: isChecked,
        };
      }
      return selectedMid;
    });
    setCurrentMerchantIds(newCurrentMerchantIds);
  };

  const isSaveButtonDisabled = currentMerchantIds.every(
    (value, index) => merchantIds[index].enabled === value.enabled
  );

  return (
    <Modal
      title="Assign Promo"
      body={
        <ModalBody
          promoName={promoName}
          currentMerchantIds={currentMerchantIds}
          handleChangeCheckbox={handleChangeCheckbox}
        />
      }
      footer={
        <ModalFooter
          onClickBack={onClickBack}
          onClickSave={() => onClickSave(currentMerchantIds)}
          isDisabled={isSaveButtonDisabled}
        />
      }
      isMaxWidth
    />
  );
};

export default AssignPromoModal;
