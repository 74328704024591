import { DateTime } from "luxon";
import React from "react";
import colors from "utils/colors";
import {
  ActionHeaderContainer,
  Button,
  ButtonUpdateStatus,
  Description,
  FormContainer,
  IcEdit,
  Label,
  LabelValueContainer,
  LeftContainer,
  PromoDescriptionContainer,
  Value,
} from "./style";
import { PromoDetailResponse } from "utils/data/promo";
import { Remarkable } from "remarkable";
import RemarkableReactRenderer from "remarkable-react";

const md = new Remarkable();
md.renderer = new RemarkableReactRenderer();

md.inline.ruler.enable(["ins"]);

type PromoBodyProps = {
  promoData: PromoDetailResponse;
  updateStatus: () => void;
  onClickEdit: () => void;
  onClickAssignMID: () => void;
};

const PromoBody: React.FC<PromoBodyProps> = (props) => {
  const { promoData, updateStatus, onClickEdit, onClickAssignMID } = props;
  return (
    <div>
      <ActionHeaderContainer>
        <LeftContainer>
          <ButtonUpdateStatus
            color="default"
            text={promoData.active ? colors.error : colors.success}
            onClick={updateStatus}
          >
            {promoData.active ? "Deactivate" : "Activate"}
          </ButtonUpdateStatus>
          <Button color="default" onClick={onClickAssignMID}>
            Assign MID to Promo
          </Button>
        </LeftContainer>
        <Button color="default" onClick={onClickEdit}>
          <IcEdit />
          Edit
        </Button>
      </ActionHeaderContainer>
      <FormContainer>
        <LabelValueContainer>
          <Label>Promo ID</Label>
          <Value>{promoData.id}</Value>
        </LabelValueContainer>
        <LabelValueContainer>
          <Label>Date Created</Label>
          <Value>
            {DateTime.fromISO(promoData.createdAt).toFormat("dd/MM/yyyy")}
          </Value>
        </LabelValueContainer>
        <LabelValueContainer>
          <Label>Promo Name</Label>
          <Value>{promoData.name}</Value>
        </LabelValueContainer>
        <LabelValueContainer>
          <Label>Promo Type</Label>
          <Value>{promoData.promoType}</Value>
        </LabelValueContainer>
        <LabelValueContainer>
          <Label>Status</Label>
          <Value color={promoData.active ? colors.success : colors.error}>
            {promoData.active ? "Active" : "Inactive"}
          </Value>
        </LabelValueContainer>
        <PromoDescriptionContainer>
          <Label>Promo Description</Label>
          {promoData.detailPromo.length > 1 ? (
            <Description>
              <ul>
                {promoData.detailPromo.map((item) => {
                  return <li key={item}>{item}</li>;
                })}
              </ul>
            </Description>
          ) : (
            <Description>{md.render(promoData.detailPromo[0])}</Description>
          )}
        </PromoDescriptionContainer>
      </FormContainer>
    </div>
  );
};

export default PromoBody;
