import { NavLink } from "react-router-dom";

import styled from "styled-components";
import { SidebarStatusProps } from "./props";

export const SidebarContainer = styled.div<SidebarStatusProps>`
  min-height: 100vh;
  background-color: #1d2c3b;
  max-width: 240px;
  min-width: 240px;
  display: ${(props) => (props.active ? "block" : "none")};
`;

export const StyledLink = styled(NavLink)`
  color: #99a6ad;
  text-decoration: none;
  background-color: #1d2c3b;
  padding-left: 32px;
  padding-top: 16px;
  padding-bottom: 16px;
  cursor: pointer;
  display: block;

  &.active {
    color: white;
    background-color: #263646;
  }
`;

export const SidebarItemList = styled.ul`
  padding: 0px;
  margin-top: 10px;
`;

export const SidebarItem = styled.li`
  list-style-type: none;
`;
