import React from "react";
import {
  ModalHeader,
  ModalBody,
  ModalContent,
  ModalDialog,
  ModalFooter,
} from "./style";

const Modal: React.FC<ModalProps> = (props) => {
  const { title, body, footer, isMaxWidth } = props;

  return (
    <ModalDialog role="dialog" aria-modal="true">
      <ModalContent role="document" isMaxWidth={isMaxWidth}>
        <ModalHeader data-testid="modal-header">{title}</ModalHeader>
        <ModalBody data-testid="modal-body">{body}</ModalBody>
        {footer && (
          <ModalFooter data-testid="modal-footer">{footer}</ModalFooter>
        )}
      </ModalContent>
    </ModalDialog>
  );
};

export default Modal;
