import React from "react";
import styled from "styled-components";

type Props = {
  value: string;
  "data-testid": string;
  onDateChange: (payload: string) => void;
};

const Container = styled.div`
  input {
    border: 1px solid #e2e2e3;
    font-size: 12px;
    font-weight: 300;
    border-radius: 4px;
    cursor: pointer;
    padding: 10px;
    box-sizing: border-box;
    outline: none;
    width: 100%;
  }
`;

const Datepicker: React.FC<Props> = (props) => {
  const { value, "data-testid": dataTestId, onDateChange } = props;

  return (
    <Container>
      <input
        type="date"
        data-testid={dataTestId}
        value={value}
        onChange={(e) => {
          const date = e.target.value;
          onDateChange(date);
        }}
      />
    </Container>
  );
};

export default Datepicker;
