import React, { Context, useEffect } from "react";
import { getAuditLogs } from "apis/audit-log";
import TableChangeLog from "components/Table/TableChangeLog";
import { AuditLogParam } from "utils/data/audit-logs";
import { columns } from "./mock";

type BodyProps = {
  auditLogParam?: {
    actions: string;
    size: number;
  };
  dispatchType: string;
  auditLogContext: Context<any>;
};

const Body: React.FC<BodyProps> = (props) => {
  const { auditLogParam, dispatchType, auditLogContext } = props;
  const { state, dispatch } = React.useContext(auditLogContext);
  const [page, setPage] = React.useState(0);

  useEffect(() => {
    const params = {
      ...auditLogParam,
      page,
    } as AuditLogParam;
    getAuditLogs(params).then((v) => {
      dispatch({ type: dispatchType, payload: v.data });
    });
  }, [auditLogParam, dispatch, dispatchType, page]);

  const constructAuditLogData = () =>
    state.data.map((v) => ({
      time: v.executedAt,
      log: v.message,
    }));

  return (
    <TableChangeLog
      data={constructAuditLogData()}
      columns={columns}
      onPaginationChange={setPage}
      variant="border"
      pagination={true}
      totalPage={state.totalPages}
    />
  );
};

export default Body;
