export const columns = [
  {
    Header: "Promo ID",
    accessor: "id",
  },
  {
    Header: "Date Created",
    accessor: "createdAt",
  },
  {
    Header: "Promo Name",
    accessor: "name",
  },
  {
    Header: "Promo Type",
    accessor: "promoType",
  },
  {
    Header: "Status",
    accessor: "active",
  },
];

export const promoDetailDefaultValue = {
  id: "",
  createdAt: "",
  name: "",
  promoType: "",
  active: false,
  additionalFields: {
    cartThreshold: 0,
    promoAmount: 0,
  },
  detailPromo: [""],
  paymentCredentialIds: [""],
  updatedAt: "",
};
