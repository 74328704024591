import { UserState } from "stores/user/type";
import { USER_LOCAL_STORAGE_KEY } from "utils/config";
import store from "store";

export const getUser = () => {
  const userLocalStorage = store.get(USER_LOCAL_STORAGE_KEY);

  if (!userLocalStorage) {
    return null;
  }

  return userLocalStorage as UserState;
};
