import { useCallback, useEffect } from "react";
import { Column, usePagination, useSortBy, useTable } from "react-table";
import Pagination from "components/common/Table/Pagination";
import { Asc, Desc, TableContainer, Tbody, Thead } from "./style";
import { DateTime } from "luxon";

export interface TableProps {
  columns: Column[];
  data: Array<any>;
  onPaginationChange: (page: number, size: number) => void;
  variant?: "border";
  pagination?: boolean;
  onRowClick?: (id: string) => void;
  onSortingChange?: (id: string, sortType: "desc" | "asc") => void;
  totalPage?: number;
}

export default function TablePromo(props: TableProps) {
  const {
    columns,
    data,
    onPaginationChange,
    variant,
    pagination = true,
    onRowClick = () => {},
    onSortingChange = () => {},
    totalPage = 0,
  } = props;

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    gotoPage,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 },
      manualPagination: true,
      pageCount: totalPage,
      manualSortBy: true,
    },
    useSortBy,
    usePagination
  );

  useEffect(() => {
    onPaginationChange(pageIndex, pageSize);
  }, [onPaginationChange, pageIndex, pageSize]);

  const handleSortingChange = useCallback(
    (id: string, isSortedDesc?: boolean) => {
      const sortType = isSortedDesc ? "desc" : "asc";
      onSortingChange(id, sortType);

      return <span>{isSortedDesc ? <Desc /> : <Asc />}</span>;
    },
    [onSortingChange]
  );

  return (
    <>
      <TableContainer {...getTableProps()}>
        <Thead variant={variant}>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => {
                return (
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    data-testid="head-columns"
                  >
                    {column.render("Header")}
                    {column.isSorted &&
                      handleSortingChange(column.id, column.isSortedDesc)}
                  </th>
                );
              })}
            </tr>
          ))}
        </Thead>
        <Tbody {...getTableBodyProps()} variant={variant}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <tr
                {...row.getRowProps()}
                data-testid="table-rows"
                onClick={() => onRowClick(row.values.id)}
              >
                {row.cells.map((cell) => {
                  const isBoolean = typeof cell.value;
                  const isDateFormat = cell.column.id === "createdAt" || false;

                  let value = cell.render("Cell");
                  let statusClassName = "";

                  if (isBoolean === "boolean") {
                    value = cell.value ? "Active" : "Inactive";
                    statusClassName = cell.value ? "active" : "inactive";
                  }

                  if (isDateFormat) {
                    value = DateTime.fromISO(cell.value).toFormat("dd/MM/yyyy");
                  }

                  return (
                    <td {...cell.getCellProps()} className={statusClassName}>
                      {value}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </Tbody>
      </TableContainer>
      {pagination && (
        <Pagination
          gotoPage={gotoPage}
          totalPage={totalPage}
          pageIndex={pageIndex}
        />
      )}
    </>
  );
}
