import { InputHTMLAttributes } from "react";
import { Label, DefaultInput, Error, Container } from "./style";

export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  required?: boolean;
  errorMessage?: string;
}

export default function Input({
  label,
  required,
  errorMessage,
  ...rest
}: InputProps) {
  return (
    <Container>
      {label && <Label required={required}>{label}</Label>}
      <DefaultInput errorMessage={errorMessage} {...rest} />
      {errorMessage && <Error>{errorMessage}</Error>}
    </Container>
  );
}
