import React, { useState, useCallback, useEffect } from "react";
import { DateTime } from "luxon";
import { toast } from "react-toastify";

import ShippingDetailModal from "components/ShippingDetailModal";
import Table from "components/common/Table";
import Button from "components/Button";
import Dropdown from "../common/Dropdown/Dropdown";
import Datepicker from "../common/Datepicker/Datepicker";
import ShippingForm from "components/ShippingForm/ShippingForm";
import queryString from "query-string";
import columns from "./columns";

import { ShippingOrder, ShippingParams } from "utils/data/shipping";
import { getShippingOrderListAPI } from "apis/shipping";

import {
  ShippingPageTitle,
  ContentContainer,
  DatepickerContainer,
  DropDownContainer,
  InputFormContainer,
  ButtonContainer,
} from "./style";
import ShippingAuditLogProvider, {
  ShippingAuditLogContext,
} from "stores/shipping-audit-logs";
import ChangeLogModal from "components/ChangeLogModal";
import { useHistory } from "react-router-dom";

type FilterTypeOption = {
  value: string;
  label: string;
};

const SHIPPING_AUDIT_LOG_PARAM = {
  size: 10,
  actions: "updateShippingStatus",
};

const ShippingPageContent: React.FC = () => {
  const [shippingOrderList, setShippingOrders] = useState<ShippingOrder[]>([]);
  const { location } = useHistory();
  const queryParams = queryString.parse(location.search);
  const defaultFilterValue = queryParams["trackingId"]
    ? String(queryParams["trackingId"])
    : "";

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [totalPage, setTotalPage] = useState(0);
  const [shouldResetPage, setShouldRefreshPage] = useState(false);
  const [showShippingDetailModal, setShowShippingDetailModal] = useState(false);
  const [showChangeLogModal, setShowChangeLogModal] = useState(false);
  const [selectedShippingDetail, setSelectedShippingOrder] =
    useState<ShippingOrder>({} as ShippingOrder);

  const dateFormat = "yyyy-MM-dd";

  const defaultFromDate = DateTime.now()
    .minus({ days: 7 })
    .startOf("day")
    .toISO();
  const [fromDate, setFromDate] = useState(defaultFromDate);
  const [toDate, setToDate] = useState(DateTime.now().toISO());
  const [filterInput, setFilterInput] = useState(defaultFilterValue);
  const [options, setOptions] = useState<ShippingParams>({
    gostoreOrderId: "",
    shipperTrackingId: defaultFilterValue,
    awbNumber: "",
  });

  const filterParams: FilterTypeOption[] = [
    { label: "Shipper ID", value: "shipperTrackingId" },
    { label: "Gostore ID", value: "gostoreOrderId" },
    { label: "Airwaybill", value: "awbNumber" },
  ];

  const [filterType, setFilterType] = useState<FilterTypeOption | null>(
    filterParams[0]
  );

  const searchByParam = () => {
    setShouldRefreshPage(!shouldResetPage); //reset pagination to first page when searching fresh data
    callShippingOrders(fromDate, toDate, options, 0, pageSize);
  };

  const handleFromDatePickerChange = useCallback(
    (date: string) => {
      setFromDate(DateTime.fromFormat(date, dateFormat).toISO());
    },
    [dateFormat]
  );

  const handleToDatePickerChange = useCallback(
    (date: string) => {
      setToDate(DateTime.fromFormat(date, dateFormat).toISO());
    },
    [dateFormat]
  );

  const onRowClick = (idx: number) => {
    setShowShippingDetailModal(true);
    setSelectedShippingOrder(shippingOrderList[idx]);
  };

  const onCloseModal = () => {
    callShippingOrders(fromDate, toDate, options, page, pageSize);
    setShowShippingDetailModal(false);
  };

  const callShippingOrders = useCallback(
    (
      fromDate: string,
      toDate: string,
      options: ShippingParams,
      page: number,
      size: number
    ) => {
      getShippingOrderListAPI(fromDate, toDate, options, page, size)
        .then((response) => {
          setShippingOrders(response.data.data);
          setTotalPage(response.data.totalPage);
        })
        .catch(() => toast.error("System failure, please try again"));
    },
    []
  );

  useEffect(() => {
    const opt: ShippingParams = {
      gostoreOrderId: "",
      shipperTrackingId: "",
      awbNumber: "",
    };

    if (filterType?.value) {
      opt[filterType?.value] = filterInput;
    }

    setOptions(opt);
  }, [filterInput, filterType]);

  useEffect(() => {
    callShippingOrders(fromDate, toDate, options, page, pageSize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, pageSize, callShippingOrders]);

  return (
    <>
      <ContentContainer>
        <ShippingPageTitle data-testid="shipping-menu-title">
          GoStore Shipping
        </ShippingPageTitle>
        <DatepickerContainer>
          <Datepicker
            data-testid="input-start-date"
            value={DateTime.fromISO(fromDate).toFormat(dateFormat)}
            onDateChange={handleFromDatePickerChange}
          />
          <Datepicker
            data-testid="input-end-date"
            value={DateTime.fromISO(toDate).toFormat(dateFormat)}
            onDateChange={handleToDatePickerChange}
          />
        </DatepickerContainer>
        <ButtonContainer>
          <Button
            data-testid="btn-submit"
            color="default"
            onClick={() => setShowChangeLogModal(true)}
            aria-label="change-log-button"
          >
            Change Logs
          </Button>
        </ButtonContainer>
        <DropDownContainer>
          <Dropdown
            inputId="select-filter"
            placeholder="Select Filter Type"
            options={filterParams}
            selectedOption={filterType}
            handleChange={setFilterType}
          />
        </DropDownContainer>
        <InputFormContainer>
          <ShippingForm
            data-testid="input-filter"
            placeholder={`Search by ${filterType?.label}`}
            value={filterInput}
            onInputChange={setFilterInput}
          />
        </InputFormContainer>

        <ButtonContainer>
          <Button data-testid="button-search" onClick={searchByParam}>
            Search
          </Button>
        </ButtonContainer>
      </ContentContainer>

      <Table
        data={shippingOrderList}
        columns={columns}
        shouldResetPage={shouldResetPage}
        onPageIndexChange={setPage}
        onPageSizeChange={setPageSize}
        onRowClick={onRowClick}
        totalPage={totalPage}
      />

      {showShippingDetailModal && (
        <ShippingDetailModal
          onClose={onCloseModal}
          values={selectedShippingDetail}
        />
      )}

      {showChangeLogModal && (
        <ShippingAuditLogProvider>
          <ChangeLogModal
            auditLogContext={ShippingAuditLogContext}
            auditLogParam={SHIPPING_AUDIT_LOG_PARAM}
            dispatchType="UPDATE_SHIPPING_AUDIT_LOG"
            onClose={() => setShowChangeLogModal(false)}
          />
        </ShippingAuditLogProvider>
      )}
    </>
  );
};

export default ShippingPageContent;
