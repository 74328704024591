import styled from "styled-components";
import { default as ButtonDefault } from "components/Button";
import IconEdit from "./ic-edit.svg";

export const ActionContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const ActionHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Button = styled(ButtonDefault)`
  min-width: 142px;
`;

export const ButtonUpdateStatus = styled(Button)<{ text: string }>`
  margin-right: 16px;
  color: ${({ text }) => text || "#5B5B5B"};
`;

export const IcEdit = styled.img.attrs({
  src: IconEdit,
  alt: "icon-edit",
})`
  display: inline-block;
  margin-right: 4px;
  vertical-align: middle;
`;

export const LeftContainer = styled.div`
  display: flex;
`;

export const FormContainer = styled.div`
  padding: 24px 32px;
  border: 1px solid #dededf;
  box-sizing: border-box;
  border-radius: 4px;
  margin-top: 24px;
  display: flex;
  flex-wrap: wrap;
`;

export const Label = styled.p`
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  color: rgba(51, 51, 51, 0.87);
`;

export const Value = styled.p`
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: ${({ color }) => color || "#333333"};
`;

export const LabelValueContainer = styled.div`
  width: calc(100% / 3);
  box-sizing: border-box;
`;

export const PromoDescriptionContainer = styled.div`
  width: 100%;
`;

export const Description = styled.div`
  background: #ffffff;
  border: 1px solid #dededf;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 16px 24px;
  width: 100%;
  max-height: 200px;
  overflow: auto;
  p {
    font-size: 14px;
  }
  li {
    font-size: 14px;
    color: #333333;
    line-height: 22px;
  }
`;
