import Button from "components/Button";
import Input from "components/Input";
import styled from "styled-components";

export const ModalContent = styled.div`
  border: 1px solid #dededf;
  border-radius: 4px;
  margin: 14px 0;
`;

export const PromoTypeContainer = styled.div`
  padding: 24px 32px;
  border-bottom: 1px solid #dededf;
  font-size: 16px;
`;

export const PromoDetailContainer = styled.div`
  padding: 24px 32px;
  p {
    text-align: center;
    font-size: 16px;
    color: #666666;
  }
`;

export const FieldLabel = styled.div`
  display: flex;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 18px;
`;

export const FieldContainer = styled.div`
  width: 257px;
`;

export const PromoNameContainer = styled.div`
  margin-bottom: 24px;
`;

export const PromoNameInput = styled(Input)`
  width: 412px;
`;

export const ActionButtons = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const CancelButton = styled(Button)`
  padding: 10px 40px;
  margin-right: 16px;
`;

export const SaveButton = styled(Button)`
  padding: 10px 40px;
`;

export const ErrorText = styled.div`
  font-size: 12px;
  color: #ee2737;
  margin-top: 8px;
`;
