import styled, { css } from "styled-components/macro";
import colors from "utils/colors";

type Color = "primary" | "default" | "danger";
type ButtonVariant = "contained" | "outlined";

interface ButtonProps {
  variant?: ButtonVariant;
  color?: Color;
  fullWidth?: boolean;
}

const getOutlinedColoredStyles = (color: Color) => css`
  color: ${colors.button[color].main};
  background: #ffffff;

  &:hover,
  &:active {
    color: ${colors.button[color].main};
  }
`;

const getContainedColoredStyles = (color: Color) => css`
  background: ${colors.button[color].main};
  border-color: ${colors.button[color].main};
  color: ${color !== "default" ? "#ffffff" : colors.text};
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);

  &:hover,
  &:active {
    background: ${colors.button[color].dark};
    border-color: ${colors.button[color].dark};
  }

  &:disabled {
    color: ${color !== "default" ? "#ffffff" : colors.text};
    background: ${colors.button[color].disable};
  }
`;

const variantStyles = (props: { variant?: ButtonVariant; color?: Color }) => {
  const { variant, color } = props;

  if (variant === "outlined") {
    return css`
      background: "#ffffff";

      ${getOutlinedColoredStyles(color!)}

      &:disabled {
        color: #ffffff;
        background: ${colors.button[color!].disable};
        border-color: ${colors.button[color!].disable};
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
      }
    `;
  }

  return css`
    ${getContainedColoredStyles(color!)}
  `;
};

const Button = styled.button<ButtonProps>`
  text-align: center;
  display: inline-block;
  padding: 10px;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  line-height: 17px;
  font-weight: bold;

  ${({ fullWidth }) =>
    fullWidth &&
    css`
      display: block;
      width: 100%;
    `}

  &:not(:disabled) {
    cursor: pointer;
  }

  &:focus {
    outline: none;
  }

  &:disabled {
    cursor: not-allowed;
  }

  ${variantStyles}
`;

Button.defaultProps = {
  color: "primary",
  variant: "contained",
};

export default Button;
