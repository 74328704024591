import React from "react";
import { logout } from "apis/user";

import {
  NavbarContainer,
  NavbarItem,
  NavbarLeft,
  NavbarRight,
  NavigationDrawer,
} from "./style";

import NavigationDrawerImage from "assets/images/navigation-drawer.svg";
import { UserContext } from "stores/user";
import { LoginModalContext } from "stores/login-modal";
import { useHistory } from "react-router-dom";

interface NavbarProps {
  isSidebarActive: boolean;
  onSidebarStatusChange?: (newStatus: boolean) => void;
  onSetIsLoginModalShown?: (isShown: boolean) => void;
}

const Navbar: React.FC<NavbarProps> = (props) => {
  const history = useHistory();
  const { state, dispatch } = React.useContext(UserContext);
  const { setIsShownModal } = React.useContext(LoginModalContext);
  const { isSidebarActive, onSidebarStatusChange = () => {} } = props;

  const handleLogout = () => {
    logout().then(() => {
      history.push("/");
      dispatch({ type: "LOGOUT" });
    });
  };

  return (
    <NavbarContainer>
      <NavbarLeft>
        {state.name && (
          <NavigationDrawer
            onClick={() => onSidebarStatusChange(!isSidebarActive)}
            src={NavigationDrawerImage}
          />
        )}
        GoStore Internal Tools
      </NavbarLeft>
      <NavbarRight>
        {state.name ? (
          <>
            <NavbarItem>{state.name}</NavbarItem>
            <NavbarItem onClick={handleLogout}>Logout</NavbarItem>
          </>
        ) : (
          <NavbarItem onClick={() => setIsShownModal(true)}>Login</NavbarItem>
        )}
      </NavbarRight>
    </NavbarContainer>
  );
};

export default Navbar;
