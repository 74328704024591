import styled from "styled-components";

export const ModalDialog = styled.div`
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: rgba(0, 0, 0, 0.75);
`;

export const ModalContent = styled.div<{ isMaxWidth?: boolean }>`
  width: ${(props) => props.isMaxWidth && "992px"};
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 992px;
  min-width: 545px;
`;

export const ModalHeader = styled.div`
  padding: 16px 36px;
  border-bottom: 1px solid #e2e2e3;
  background-color: #fff;
  font-size: 24px;
  font-weight: bold;
`;

export const ModalBody = styled.div`
  background-color: #fff;
  min-height: 65px;
  padding: 16px 36px;
  max-height: 450px;
  overflow-y: auto;
`;

export const ModalFooter = styled.div`
  background-color: #fafafa;
  padding: 18px 36px;
`;
