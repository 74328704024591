const columns = [
  {
    Header: "Merchant",
    accessor: "merchant.name",
  },
  {
    Header: "Customer",
    accessor: "customer.name",
  },
  {
    Header: "Logo",
    accessor: "courier.logo",
    id: "img",
  },
  {
    Header: "Shipper ID",
    accessor: "shipperTrackingId",
  },
  {
    Header: "Gostore ID",
    accessor: "gostoreOrderId",
  },
  {
    Header: "Aiway bill",
    accessor: "awbNumber",
  },
  {
    Header: "GoStore Status",
    accessor: "gostoreStatus",
  },
  {
    Header: "Shipper Status",
    accessor: "shipperStatus",
  },
  {
    Header: "Status",
    accessor: "status",
    id: "status",
  },
  {
    Header: "Creation Date",
    accessor: "creationDate",
  },
];

export default columns;
