import ShippingForm from "components/ShippingForm/ShippingForm";
import Dropdown from "components/common/Dropdown";
import React, { useCallback, useState } from "react";
import { CancelButton } from "components/MapBusinessesModal/style";
import {
  TrackingTitleContainer,
  UpdateFormActionContainer,
  UpdateFormContainer,
  Value,
  UpdateButton,
} from "./style";
import Button from "components/Button";
import { UserContext } from "stores/user";
import { getShippingOrderStatusAPI } from "apis/shipping";
import {
  RawShippingStatus,
  ShippingOrder,
  ShippingStatusRequest,
} from "utils/data/shipping";
import { toast } from "react-toastify";
import ShippingStatusModal from "components/ShippingStatusModal/ShippingStatusModal";

type Option = {
  value: string;
  label: string;
  id?: number;
};

type ShippingFormProps = {
  onSubmit: (values: ShippingOrder) => void;
  values: ShippingOrder;
};

const ShippingFormModal: React.FC<ShippingFormProps> = (props) => {
  const { values, onSubmit } = props;
  const { state } = React.useContext(UserContext);
  const [showUpdateForm, setShowUpdateForm] = useState(false);
  const [showUpdateModal, setShowUpateModal] = useState(false);

  const [options, setOptions] = useState<Option[]>([]);
  const [selectedOption, setSelectedOption] = useState<Option | null>({
    label: "Select Status",
    value: "",
    id: 0,
  });
  const [description, setDescription] = useState("");

  const getShippingStatus = useCallback(async () => {
    const response = await getShippingOrderStatusAPI();
    if (response.status < 300) {
      let opt: Option[] = [];
      response.data.data.forEach((rawStatus: RawShippingStatus) => {
        opt.push({
          label: rawStatus.status,
          value: rawStatus.status,
          id: rawStatus.id,
        });
      });
      setOptions(opt);
    } else {
      toast.error("System failure, please try agaIn.");
    }
  }, []);

  const onEditClick = () => {
    getShippingStatus();
    setShowUpdateForm(true);
  };

  const onSubmitForm = () => {
    if (selectedOption?.id) {
      setShowUpateModal(true);
    } else {
      toast.error("Please select tracking status.");
    }
  };

  return (
    <>
      <TrackingTitleContainer>
        {showUpdateForm ? (
          <UpdateFormContainer data-testid="form-status-update">
            <Value>Status*</Value>
            <Dropdown
              inputId="select-status"
              placeholder="Select Status"
              options={options}
              selectedOption={selectedOption}
              handleChange={setSelectedOption}
            />
            <Value>Description</Value>
            <ShippingForm
              data-testid="input-description"
              placeholder="Please put description..."
              value={description}
              onInputChange={setDescription}
            />
            <UpdateFormActionContainer>
              <CancelButton
                data-testid="button-cancel"
                color="default"
                onClick={() => {
                  setShowUpdateForm(false);
                }}
              >
                Cancel
              </CancelButton>
              <UpdateButton data-testid="button-update" onClick={onSubmitForm}>
                Update
              </UpdateButton>
              {showUpdateModal && (
                <ShippingStatusModal
                  onClose={() => setShowUpateModal(false)}
                  onSubmit={onSubmit}
                  shippingData={values}
                  statusRequest={
                    {
                      shippingId: values.id,
                      status: {
                        id: selectedOption?.id,
                        name: selectedOption?.value,
                        description: description,
                      },
                      updatedBy: state.name,
                    } as ShippingStatusRequest
                  }
                />
              )}
            </UpdateFormActionContainer>
          </UpdateFormContainer>
        ) : (
          <Button
            data-testid="button-edit"
            color="default"
            onClick={onEditClick}
          >
            Edit
          </Button>
        )}
      </TrackingTitleContainer>
    </>
  );
};

export default ShippingFormModal;
